import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, mergeClasses, slot, } from "@fluentui/react-components";
function captitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
function useCol({ sm, md, lg, xl, xxl, xxxl, ...props }, ref) {
    return {
        components: { root: "div" },
        sizes: { sm, md, lg, xl, xxl, xxxl },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useColStyles(state) {
    const classes = [];
    let size;
    for (size in state.sizes) {
        const spec = state.sizes[size];
        if (spec == null) {
            continue;
        }
        if (typeof spec === "number" || typeof spec === "string") {
            classes.push(`ms-${size}${spec}`);
        }
        else {
            classes.push(`ms-${size}${spec.span}`);
            if (spec.pull != null) {
                classes.push(`ms-${size}Pull${spec.pull}`);
            }
            if (spec.push != null) {
                classes.push(`ms-${size}Push${spec.push}`);
            }
            if (spec.offset != null) {
                classes.push(`ms-${size}Offset${spec.offset}`);
            }
            if (spec.hidden === "up") {
                classes.push(`ms-hidden${captitalize(size)}Up`);
            }
            else if (spec.hidden === "down") {
                classes.push(`ms-hidden${captitalize(size)}Down`);
            }
            else if (spec.hidden) {
                classes.push(`ms-hidden${captitalize(size)}`);
            }
        }
    }
    state.root.className = mergeClasses("ms-Grid-col", ...classes, state.root.className);
}
function renderCol(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Col = forwardRef((props, ref) => {
    const state = useCol(props, ref);
    useColStyles(state);
    return renderCol(state);
});
Col.displayName = "Col";
export default Col;
