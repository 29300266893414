import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

import {
  DarkquellInferredSkill,
  LLMNEREntity,
} from "@aglocal/web/schema/ModelOutput";

import Callout from "@aglocal/web/components/Callout";

import SkillPills from "@/components/SkillPills";

export interface SkillsAccordionItemProps {
  name: unknown;
  skills: readonly DarkquellInferredSkill[];
  entities: readonly LLMNEREntity[];
}

export default function SkillsAccordionItem({
  name,
  skills,
  entities,
}: SkillsAccordionItemProps) {
  return (
    <AccordionItem value={name}>
      <AccordionHeader>Skills, Job Titles, Organizations</AccordionHeader>
      <AccordionPanel>
        {skills.length === 0 && entities.length === 0 ? (
          <Callout intent="warning" title="No skills found" />
        ) : (
          <SkillPills skills={skills} entities={entities} />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}
