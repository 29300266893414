import { Configuration, LogLevel } from "@azure/msal-browser";

import { MICROSOFT_APP_ID, MICROSOFT_TENANT_ID, PUBLIC_HOSTNAME } from "@/config";

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: MICROSOFT_APP_ID,
    authority: `https://login.microsoftonline.com/${MICROSOFT_TENANT_ID}`,
    redirectUri: `${window.location.origin}/app.html`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          if (level === LogLevel.Error) {
            reportError(new Error("MSAL Error"));
          }
          return;
        }
        switch (level) {
          case LogLevel.Error:
            reportError(new Error(message));
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const MSAL_SCOPES: string[] = [
  `api://${PUBLIC_HOSTNAME}/${MICROSOFT_APP_ID}/access_as_user`.replace(
    "talentassistant",
    "missioncontrol",
  ),
];
