import {
  Control,
  Controller,
  useFormState,
} from "react-hook-form";

import {
  Button,
  Field,
  makeStyles,
  mergeClasses,
  Radio,
  RadioGroup,
  shorthands,
  Spinner,
  tokens,
} from "@fluentui/react-components";

import Copyright from "@/components/Copyright";

import { PostMeetingFormValues } from "./form";
import Container from "@aglocal/web/components/Container";
import { mediaQuery } from "@aglocal/web/helpers/responsive";
import Stack from "@aglocal/web/components/Stack/Stack";

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.borderTop(
      tokens.strokeWidthThin,
      "solid",
      tokens.colorNeutralStroke1,
    ),
  },
  container: {
    paddingTop: tokens.spacingVerticalM,
    paddingBottom: tokens.spacingVerticalM,
    [mediaQuery({ min: "xl" })]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: 0,
    columnGap: tokens.spacingHorizontalM,
    rowGap: tokens.spacingVerticalM,
    [mediaQuery({ min: "lg" })]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  meetingTypeField: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: tokens.spacingHorizontalS,
  },
  submitButton: {
    width: "100%",
    [mediaQuery({ min: "lg" })]: {
      width: "auto",
    },
  },
});

export interface PostMeetingFooterProps {
  className?: string;
  control: Control<PostMeetingFormValues>;
}

export default function PostMeetingFooter({
  className,
  control,
}: PostMeetingFooterProps) {
  const styles = useStyles();
  const { isSubmitting, isSubmitSuccessful } = useFormState({
    control,
    name: [],
  });

  const getSubmitButtonContent = () => {
    if (isSubmitting) {
      return (
        <Stack gap="xs" verticalAlign="center" horizontal>
          <Spinner size="tiny" />
          Updating Connected...
        </Stack>
      );
    }
    if (isSubmitSuccessful) {
      return "Connected Updated";
    }
    return "Update Connected";
  };

  return (
    <footer className={mergeClasses(styles.root, className)}>
      <Container className={styles.container}>
        <div className={styles.form}>
          <Controller
            control={control}
            name="meetingType"
            render={({
              field: { onChange, ...field },
              fieldState: { error },
              formState: { isSubmitting, isSubmitSuccessful },
            }) => (
              <Field
                label="Meeting Type"
                className={styles.meetingTypeField}
                validationState={error ? "error" : undefined}
                validationMessage={error?.message}
              >
                <RadioGroup
                  {...field}
                  onChange={(event, { value }) => {
                    onChange(value);
                  }}
                  layout="horizontal"
                  disabled={isSubmitting || isSubmitSuccessful}
                >
                  <Radio value="G2" label="G2" />
                  <Radio value="STP" label="Service Touchpoint" />
                  <Radio value="Other" label="Other" />
                </RadioGroup>
              </Field>
            )}
          />
          <Button
            type="submit"
            appearance="primary"
            className={styles.submitButton}
            disabled={isSubmitting || isSubmitSuccessful}
          >
            {getSubmitButtonContent()}
          </Button>
        </div>
        <Copyright />
      </Container>
    </footer>
  );
}
