import assertNever from "@aglocal/web/helpers/assertNever";

import { AuthState } from "@/auth";

export default function getUserId(auth: AuthState) {
  switch (auth.provider) {
    case "msal":
      return auth.account.localAccountId;
    case "teams":
      return auth.user?.id;
    default:
      assertNever(auth);
  }
}
