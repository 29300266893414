import { makeStyles, mergeClasses, renderText_unstable, typographyStyles, useText_unstable, useTextStyles_unstable, } from "@fluentui/react-components";
const whiteSpaceStyles = {
    normal: {
        whiteSpace: "normal",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    pre: {
        whiteSpace: "pre",
    },
    "pre-wrap": {
        whiteSpace: "pre-wrap",
    },
    "pre-line": {
        whiteSpace: "pre-line",
    },
    "break-spaces": {
        whiteSpace: "break-spaces",
    },
};
const useStyles = makeStyles(typographyStyles);
const useWhiteSpaceStyles = makeStyles(whiteSpaceStyles);
export function useTypography({ variant, whiteSpace, ...props }, ref) {
    return {
        ...useText_unstable(props, ref),
        variant,
        whiteSpace,
    };
}
export function useTypographyStyles(state) {
    const styles = useStyles();
    const whiteSpace = useWhiteSpaceStyles();
    useTextStyles_unstable(state);
    state.root.className = mergeClasses(state.root.className, state.variant && styles[state.variant], state.whiteSpace && whiteSpace[state.whiteSpace]);
}
export function renderTypography(state) {
    return renderText_unstable(state);
}
