import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, makeStyles, mergeClasses, slot, tokens, } from "@fluentui/react-components";
import { SCREEN_MIN_SM, SCREEN_MIN_MD, SCREEN_MIN_LG, SCREEN_MIN_XL, SCREEN_MIN_XXL, SCREEN_MIN_XXXL, } from "@aglocal/web/helpers/responsive";
const useStyles = makeStyles({
    root: {
        marginLeft: "auto",
        marginRight: "auto",
        paddingLeft: tokens.spacingHorizontalS,
        paddingRight: tokens.spacingHorizontalS,
    },
    sm: {
        maxWidth: `${SCREEN_MIN_SM}px`,
    },
    md: {
        maxWidth: `${SCREEN_MIN_MD}px`,
    },
    lg: {
        maxWidth: `${SCREEN_MIN_LG}px`,
    },
    xl: {
        maxWidth: `${SCREEN_MIN_XL}px`,
    },
    xxl: {
        maxWidth: `${SCREEN_MIN_XXL}px`,
    },
    xxxl: {
        maxWidth: `${SCREEN_MIN_XXXL}px`,
    },
});
function useContainer({ size = "xxl", ...props }, ref) {
    return {
        size,
        components: { root: "div" },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useContainerStyles(state) {
    const styles = useStyles();
    state.root.style = state.root.style ?? {};
    state.root.className = mergeClasses("ag-Container", styles.root, styles[state.size], state.root.className);
}
function renderContainer(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Container = forwardRef((props, ref) => {
    const state = useContainer(props, ref);
    useContainerStyles(state);
    return renderContainer(state);
});
Container.displayName = "Container";
export default Container;
