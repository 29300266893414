import { useEffect, useMemo, useState } from "react";
import { Control, useController } from "react-hook-form";

import { AddRegular } from "@fluentui/react-icons";
import {
  Button,
  Field,
  Input,
  makeStyles,
  mergeClasses,
  tokens,
} from "@fluentui/react-components";

import Interview from "@aglocal/web/schema/Interview";
import { DarkquellInferredSkill } from "@aglocal/web/schema/ModelOutput";
import UniqueList from "@aglocal/web/helpers/UniqueList";
import normalizeSkill from "@aglocal/web/helpers/normalizeSkill";

import Tag, { TagGroup } from "@aglocal/web/components/Tag";
import Stack from "@aglocal/web/components/Stack";

import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormValues } from "./form";

const useStyles = makeStyles({
  unclickable: {
    pointerEvents: "none",
  },
  dismissIcon: {
    cursor: "pointer",
    pointerEvents: "auto",
  },
  old: {
    backgroundColor: tokens.colorPaletteBlueBackground2,
  },
  new: {
    backgroundColor: tokens.colorPaletteGreenBackground2,
  },
  removed: {
    backgroundColor: tokens.colorPaletteRedBackground2,
  },
});

interface SkillTagProps {
  value: string;
  variant: "old" | "new" | "removed";
  disabled?: boolean;
}

function SkillTag({ value, variant, disabled }: SkillTagProps) {
  const styles = useStyles();
  return (
    <Tag
      as="span"
      className={mergeClasses(styles.unclickable, styles[variant])}
      value={value}
      dismissIcon={
        disabled
          ? null
          : {
              className: styles.dismissIcon,
              "aria-label": "remove",
              ...(variant === "removed" && {
                "aria-label": "add",
                children: <AddRegular />,
              }),
            }
      }
      dismissible={!disabled}
    >
      {value}
    </Tag>
  );
}

export interface SkillsEditorProps {
  interview: Interview;
  skills: readonly DarkquellInferredSkill[];
  control: Control<PostMeetingFormValues>;
  setDefault: UseFormSetDefault<PostMeetingFormValues>;
}

export default function SkillsEditor({
  control,
  setDefault,
  ...props
}: SkillsEditorProps) {
  const {
    field: { onChange, value },
    formState: { isSubmitting, isSubmitSuccessful },
  } = useController({
    control,
    name: "skills",
  });

  const [input, setInput] = useState("");

  const skills = useMemo(() => new UniqueList(value, normalizeSkill), [value]);

  const connectedSkills = useMemo(
    () =>
      new UniqueList(
        props.interview.interviewee.connectedDetails?.skills,
        normalizeSkill,
      ),
    [props.interview],
  );

  const modelSkills = useMemo(
    () =>
      new UniqueList(
        props.skills.map(({ skill }) => skill.name),
        normalizeSkill,
      ),
    [props.skills],
  );

  const defaultSkills = useMemo(
    () => UniqueList.union(connectedSkills, modelSkills),
    [connectedSkills, modelSkills],
  );

  const removedSkills = useMemo(
    () => UniqueList.difference(defaultSkills, skills),
    [defaultSkills, skills],
  );

  useEffect(() => {
    setDefault("skills", Array.from(defaultSkills));
  }, [defaultSkills, setDefault]);

  const handleRemove = (skill: string) => {
    onChange(value.filter((s) => s !== skill));
  };

  const handleAdd = (skill: string) => {
    if (skills.contains(skill)) {
      return;
    }
    onChange([...value, skill]);
  };

  const normalizedInput = input.trim().replace(/\s+/g, " ");
  const handleAddNew = () => {
    if (!normalizedInput.length) {
      return;
    }
    handleAdd(normalizedInput);
    setInput("");
  };

  return (
    <Stack gap="m">
      <Field label="Skills">
        <TagGroup
          onDismiss={(event, { value }) => {
            handleRemove(value);
          }}
        >
          {skills.map((skill) => (
            <SkillTag
              key={skill}
              value={skill}
              variant={connectedSkills.contains(skill) ? "old" : "new"}
              disabled={isSubmitting || isSubmitSuccessful}
            />
          ))}
        </TagGroup>
      </Field>
      {removedSkills.length > 0 && (
        <Field label="Removed Skills">
          <TagGroup
            onDismiss={(event, { value }) => {
              handleAdd(value);
            }}
          >
            {removedSkills.map((skill) => (
              <SkillTag
                key={skill}
                value={skill}
                variant="removed"
                disabled={isSubmitting || isSubmitSuccessful}
              />
            ))}
          </TagGroup>
        </Field>
      )}
      <Field label="Additional Skills">
        <Input
          value={input}
          disabled={isSubmitting || isSubmitSuccessful}
          onChange={(event) => {
            setInput(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              handleAddNew();
            }
          }}
          contentAfter={
            <Button
              icon={<AddRegular />}
              aria-label="Add skill"
              appearance="transparent"
              size="small"
              disabled={
                !normalizedInput.length || isSubmitting || isSubmitSuccessful
              }
              onClick={(event) => {
                event.preventDefault();
                handleAddNew();
              }}
            />
          }
        />
      </Field>
    </Stack>
  );
}
