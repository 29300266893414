import { ReactNode } from "react";

import { FormattedDate } from "react-intl";
import { Link, makeStyles, tokens } from "@fluentui/react-components";

import { ConnectedDetails as ConnectedDetailsType } from "@aglocal/web/schema/Interview";
import Tag, { TagGroup } from "@aglocal/web/components/Tag";
import { Grid, Row, Col } from "@aglocal/web/components/Grid";
import { Span } from "@aglocal/web/components/Typography";

const useStyles = makeStyles({
  skill: {
    backgroundColor: tokens.colorPaletteBlueBackground2,
  },
});

const useDetailStyles = makeStyles({
  root: {
    marginLeft: 0,
    marginBottom: tokens.spacingVerticalS,
  },
  title: {
    fontWeight: tokens.fontWeightBold,
  },
  value: {
    marginLeft: 0,
  },
});

function formatLocation({ mailingCity, mailingCountry }: ConnectedDetailsType) {
  const parts = [mailingCity, mailingCountry]
    .map((value) => value?.trim())
    .filter(Boolean);
  return parts.length > 0 ? parts.join(", ") : null;
}

interface DetailProps {
  title: string;
  children: ReactNode;
}

function Detail({ title, children }: DetailProps) {
  const styles = useDetailStyles();
  return (
    <Row as="dl" className={styles.root}>
      <Col as="dt" sm="3" className={styles.title}>
        {title}
      </Col>
      <Col as="dd" sm="9" className={styles.value}>
        {children}
      </Col>
    </Row>
  );
}

export interface ConnectedDetailsProps {
  details: ConnectedDetailsType;
}

export default function ConnectedDetails({ details }: ConnectedDetailsProps) {
  const styles = useStyles();
  return (
    <Grid>
      <Detail title="Job Title">
        {details.jobTitle?.trim() &&
        details.jobTitle.toLowerCase() !== "unknown" ? (
          details.jobTitle
        ) : (
          <Span italic>Unknown</Span>
        )}
      </Detail>
      <Detail title="Location">
        {formatLocation(details) ?? <Span italic>Unknown</Span>}
      </Detail>
      <Detail title="Resume Received">
        {details.latestResumeDate ? (
          <FormattedDate value={details.latestResumeDate} />
        ) : (
          <Span italic>No resume on file</Span>
        )}
      </Detail>
      <Detail title="Previous G2 Notes">
        {details.g2Notes ? (
          <Span whiteSpace="break-spaces">{details.g2Notes}</Span>
        ) : (
          <Span italic>No previous meeting notes</Span>
        )}
      </Detail>
      <Detail title="Skills">
        {details.skills.length > 0 ? (
          <TagGroup>
            {details.skills.map((skill, index) => (
              <Tag key={index} className={styles.skill}>
                {skill}
              </Tag>
            ))}
          </TagGroup>
        ) : (
          <Span italic>No skills on file</Span>
        )}
      </Detail>
      <Detail title="Connected">
        <Link
          as="a"
          href={`https://allegisgroup.lightning.force.com/lightning/r/Contact/${details.contactId}/view`}
          target="_blank"
          rel="noopener noreferrer"
        >
          View In Connected
        </Link>
      </Detail>
    </Grid>
  );
};
