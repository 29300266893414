import { useCallback } from "react";
import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";

export interface UseFormSetDefaultOptions<TFieldValues extends FieldValues> {
  getFieldState: UseFormGetFieldState<TFieldValues>;
  getValues: UseFormGetValues<TFieldValues>;
  resetField: UseFormResetField<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
}

export type UseFormSetDefault<TFieldValues extends FieldValues> = <
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  name: TFieldName,
  defaultValue: FieldPathValue<TFieldValues, TFieldName>,
) => void;

export default function useSetDefault<TFieldValues extends FieldValues>({
  getFieldState,
  getValues,
  resetField,
  setValue,
}: UseFormSetDefaultOptions<TFieldValues>): UseFormSetDefault<TFieldValues> {
  return useCallback(
    <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
      name: TFieldName,
      defaultValue: FieldPathValue<TFieldValues, TFieldName>,
    ) => {
      const value = getFieldState(name).isDirty
        ? getValues(name)
        : defaultValue;

      resetField(name, { defaultValue });
      setValue(name, value, { shouldDirty: true });
    },
    [getFieldState, getValues, resetField, setValue],
  );
}
