export class AuthError extends Error {
    constructor(message) {
        super(message);
        this.name = "AuthError";
    }
}
function isExpired(state) {
    if (state == null) {
        return true;
    }
    if (state.expires == null) {
        return false;
    }
    const now = Date.now();
    return state.expires < now;
}
export class CachedTokenAuthManager {
    constructor(fetchToken) {
        Object.defineProperty(this, "fetchToken", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: fetchToken
        });
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
    }
    async getToken(signal) {
        if (isExpired(this.state)) {
            this.state = await this.fetchToken(signal);
        }
        return this.state.token;
    }
    // eslint-disable-next-line @typescript-eslint/require-await
    async reset() {
        this.state = null;
    }
}
export class AnonymousAuthManager {
    // eslint-disable-next-line @typescript-eslint/require-await
    async getToken() {
        throw new AuthError("Not authenticated");
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    async reset() { }
}
