import { useEffect, useMemo } from "react";
import {
  Control,
  Controller,
  UseFormGetFieldState,
  UseFormGetValues,
  UseFormResetField,
  UseFormSetValue,
} from "react-hook-form";

import { Field, Input, Textarea } from "@fluentui/react-components";

import Interview from "@aglocal/web/schema/Interview";
import {
  DarkquellInferredSkill,
  LLMNEREntity,
} from "@aglocal/web/schema/ModelOutput";

import titleCase from "@aglocal/web/helpers/titleCase";
import Stack from "@aglocal/web/components/Stack";
import { Span } from "@aglocal/web/components/Typography";

import getJobTitle from "@/helpers/getJobTitle";
import { UseFormSetDefault } from "@/hooks/useSetDefault";

import { PostMeetingFormValues } from "./form";
import SkillsEditor from "./SkillsEditor";
import SummaryEditor from "./SummaryEditor";

export interface PostMeetingTabProps {
  interview: Interview;
  skills: readonly DarkquellInferredSkill[];
  entities: readonly LLMNEREntity[];
  control: Control<PostMeetingFormValues>;
  getFieldState: UseFormGetFieldState<PostMeetingFormValues>;
  getValues: UseFormGetValues<PostMeetingFormValues>;
  resetField: UseFormResetField<PostMeetingFormValues>;
  setDefault: UseFormSetDefault<PostMeetingFormValues>;
  setValue: UseFormSetValue<PostMeetingFormValues>;
}

export default function PostMeetingTab({
  interview,
  skills,
  entities,
  control,
  getValues,
  getFieldState,
  resetField,
  setDefault,
  setValue,
}: PostMeetingTabProps) {
  const currentJobTitle = getJobTitle(interview);

  const defaultJobTitle = useMemo(() => {
    const entity = entities.find(({ label }) => label === "TITLE");
    return entity ? titleCase(entity.text) : currentJobTitle ?? "";
  }, [entities, currentJobTitle]);

  useEffect(() => {
    setDefault("jobTitle", defaultJobTitle);
  }, [defaultJobTitle, setDefault]);

  return (
    <Stack gap="m">
      <Controller
        control={control}
        name="jobTitle"
        render={({
          field,
          fieldState: { error },
          formState: { isSubmitting, isSubmitSuccessful },
        }) => (
          <Field
            label="Job Title"
            hint={
              currentJobTitle && (
                <>
                  Current Title:{" "}
                  <Span size={200} weight="bold">
                    {currentJobTitle}
                  </Span>
                </>
              )
            }
            validationState={error ? "error" : undefined}
            validationMessage={error?.message}
          >
            <Input {...field} disabled={isSubmitting || isSubmitSuccessful} />
          </Field>
        )}
      />
      <Controller
        control={control}
        name="notes"
        render={({
          field,
          fieldState: { error },
          formState: { isSubmitting, isSubmitSuccessful },
        }) => (
          <Field
            label="Notes"
            validationState={error ? "error" : undefined}
            validationMessage={error?.message}
          >
            <Textarea
              {...field}
              rows={5}
              disabled={isSubmitting || isSubmitSuccessful}
            />
          </Field>
        )}
      />
      <SummaryEditor
        interview={interview}
        control={control}
        getFieldState={getFieldState}
        getValues={getValues}
        resetField={resetField}
        setValue={setValue}
      />
      <SkillsEditor
        interview={interview}
        skills={skills}
        control={control}
        setDefault={setDefault}
      />
    </Stack>
  );
}
