import { useMemo } from "react";
import { FormattedNumber } from "react-intl";
import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

import Interview from "@aglocal/web/schema/Interview";
import { past } from "@aglocal/web/helpers/time";

import Callout, { CalloutProps } from "@aglocal/web/components/Callout";

interface Action extends CalloutProps {
  key: string;
}

function getResumeAction({ interviewee: { connectedDetails } }: Interview): Action | null {
  if (!connectedDetails?.latestResumeDate) {
    return {
      key: "resume",
      intent: "error",
      title: "We currently do not have a resume.",
      children: "Please ask them to send one.",
    };
  }

  const resumeDate = new Date(connectedDetails.latestResumeDate);
  if (resumeDate < past({ months: 1 })) {
    return {
      key: "resume",
      intent: "warning",
      title: "The resume we have is over a month old.",
      children: "Check that there are no changes in employment.",
    }
  }

  if (resumeDate < past({ years: 1 })) {
    return {
      key: "resume",
      intent: "error",
      title: "The resume we have is over a year old.",
      children: "Please ask them to send a new one.",
    };
  }

  return null;
}

export interface ActionItemsAccordionItemProps {
  name: unknown;
  interview: Interview;
}

export default function ActionItemsAccordionItem({ name, interview }: ActionItemsAccordionItemProps) {
  const items = useMemo<Action[]>(() => {
      const result = [];
      const resumeAction = getResumeAction(interview);
      if (resumeAction) {
        result.push(resumeAction);
      }
      return result;
    },
    [interview]
  );

  return (
    <AccordionItem value={name}>
      <AccordionHeader>
        Action Items (<FormattedNumber value={items.length} />)
      </AccordionHeader>
      <AccordionPanel>
        {items.map(({ key, ...props }) => <Callout key={key} {...props} />)}
      </AccordionPanel>
    </AccordionItem>
  );
}
