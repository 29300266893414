import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, makeStyles, mergeClasses, slot, } from "@fluentui/react-components";
const ALIGN_VARIABLE = "--ag-stack-item--align";
const ALIGN_TOKEN = `var(${ALIGN_VARIABLE})`;
const ORDER_VARIABLE = "--ag-stack-item--order";
const ORDER_TOKEN = `var(${ORDER_VARIABLE})`;
const useStyles = makeStyles({
    root: {
        height: "auto",
        width: "auto",
        flexShrink: 1,
        alignSelf: ALIGN_TOKEN,
        order: ORDER_TOKEN,
    },
    disableShrink: {
        flexShrink: 0,
    },
    grow: {
        flexGrow: 1,
    },
    shrink: {
        flexShrink: 1,
    },
    verticalFill: {
        height: "100%",
    },
});
function useStackItem({ align = null, disableShrink = false, grow = false, order = null, shrink = false, verticalFill = false, ...props }, ref) {
    return {
        align,
        disableShrink,
        grow,
        order,
        shrink,
        verticalFill,
        components: { root: "div" },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useStackItemStyles(state) {
    const styles = useStyles();
    state.root.style = state.root.style ?? {};
    if (state.align != null) {
        state.root.style[ALIGN_VARIABLE] = state.align;
    }
    if (state.order != null) {
        state.root.style[ORDER_VARIABLE] = state.order;
    }
    state.root.className = mergeClasses("ag-StackItem", styles.root, state.disableShrink && styles.disableShrink, state.grow && styles.grow, state.shrink && styles.shrink, state.verticalFill && styles.verticalFill, state.root.className);
}
function renderStackItem(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const StackItem = forwardRef((props, ref) => {
    const state = useStackItem(props, ref);
    useStackItemStyles(state);
    return renderStackItem(state);
});
StackItem.displayName = "StackItem";
export default StackItem;
