import { ReactNode, useMemo } from "react";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { MSAL_CONFIG } from "./config";
import MsalAuthenticated from "./MsalAuthenticated";

export interface AuthProviderProps {
  children: ReactNode;
}

export default function AuthProvider({ children }: AuthProviderProps) {
  const instance = useMemo(() => new PublicClientApplication(MSAL_CONFIG), []);

  return (
    <MsalProvider instance={instance}>
      <MsalAuthenticated>
        {children}
      </MsalAuthenticated>
    </MsalProvider>
  );
}
