import { useMemo } from "react";

import { DismissRegular } from "@fluentui/react-icons";
import { Badge, Button } from "@fluentui/react-components";

import type {
  Interview,
  RecallStatus as Status,
} from "@aglocal/web/schema/Interview";
import { useMissionControl } from "@aglocal/web/mission-control";
import titleCase from "@aglocal/web/helpers/titleCase";
import useSafeState from "@aglocal/web/hooks/useSafeState";

import Callout, { CalloutProps } from "@aglocal/web/components/Callout";
import { Span } from "@aglocal/web/components/Typography";
import Stack from "@aglocal/web/components/Stack";

function getBadgeColor(status: Status | undefined) {
  switch (status) {
    case "in_call_recording":
      return "success";
    case "joining_call":
    case "participant_in_waiting_room":
    case "in_call_not_recording":
    case "recording_permission_allowed":
      return "warning";
    case "in_waiting_room":
    case "recording_permission_denied":
    case "fatal":
      return "danger";
    default:
      return "informative";
  }
}

function formatStatus(status: Status | undefined) {
  if (status == null) {
    return "unknown";
  }
  return titleCase(status.replace(/_/g, " "));
}

function isBotRunning(status: Status | undefined) {
  return !["done", "fatal", "call_ended", "ready", "", undefined].includes(status);
}

export interface RecallStatusProps {
  interview: Interview;
}

export default function RecallStatus({
  interview: { id, recall, joinWebUrl },
}: RecallStatusProps) {
  const api = useMissionControl();
  const [loading, setLoading] = useSafeState(false);
  const [callout, setCallout] = useSafeState<CalloutProps | null>(null);

  const isRunning = useMemo(
    () => isBotRunning(recall?.status),
    [recall?.status],
  );

  const startBot = async (teamsMeetingUrl: string) => {
    setLoading(true);
    try {
      await api.startMeetingBot({
        iId: id,
        teamsMeetingUrl,
      });
      setCallout({
        title: "AGi Talent Assistant started",
        intent: "success",
      });
    } catch (error) {
      setCallout({
        title: "Failed to start AGi Talent Assistant",
        intent: "error",
      });
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  const stopBot = async (botId: string) => {
    setLoading(true);
    try {
      await api.stopMeetingBot({ botId });
      setCallout({
        title: "AGi Talent Assistant stopped",
        intent: "info",
      });
    } catch (error) {
      setCallout({
        title: "Failed to stop AGi Talent Assistant",
        intent: "error",
      });
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack gap="s">
      <Stack horizontal verticalAlign="center" gap="s">
        <Badge color={getBadgeColor(recall?.status)} />
        <Span>Recall Status: {formatStatus(recall?.status)}</Span>
      </Stack>
      {callout && (
        <Callout
          containerAction={
            <Button
              onClick={() => {
                setCallout(null);
              }}
              aria-label="dismiss"
              appearance="transparent"
              icon={<DismissRegular />}
            />
          }
          {...callout}
        />
      )}
      {!isRunning && recall?.status === "ready" && joinWebUrl && (
        <Button disabled={loading} onClick={() => void startBot(joinWebUrl)}>
          Start AGi Talent Assistant
        </Button>
      )}
      {isRunning && recall?.botId && (
        <Button
          disabled={loading}
          size="small"
          onClick={() => void stopBot(recall.botId)}
        >
          Stop AGi Talent Assistant
        </Button>
      )}
    </Stack>
  );
}
