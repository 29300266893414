import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { makeStyles, mergeClasses, tokens, MessageBar, MessageBarActions, MessageBarBody, MessageBarTitle, } from "@fluentui/react-components";
const useStyles = makeStyles({
    container: {
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
    },
    messageBody: {
        whiteSpace: "normal",
    },
});
export default function Callout({ title, children, actions, containerAction, className, layout = "singleline", ...props }) {
    const styles = useStyles();
    return (_jsxs(MessageBar, { ...props, layout: layout, className: mergeClasses(styles.container, className), children: [_jsxs(MessageBarBody, { className: styles.messageBody, children: [title && _jsx(MessageBarTitle, { children: title }), children] }), (actions ?? containerAction) && (_jsx(MessageBarActions, { containerAction: containerAction, children: actions }))] }));
}
