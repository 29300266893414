import { createContext, useContext } from "react";

import { app } from "@microsoft/teams-js";

import { ThemeName, ThemeState } from "./theme";

export interface LoadingTeamsState {
  loading: true;
  inTeams: undefined;
}

export interface InTeamsState {
  loading: false;
  inTeams: true;
  isFullScreen: boolean | undefined;
  context: app.Context;
}

export interface OutOfTeamsState {
  loading: false;
  inTeams: false;
}

export type AppState = LoadingTeamsState | InTeamsState | OutOfTeamsState;

export type TeamsState = AppState &
  ThemeState & { setTheme: (theme: ThemeName) => void };

export const TeamsContext = createContext<TeamsState | null>(null);

export function useTeams(): TeamsState {
  const context = useContext(TeamsContext);
  if (context == null) {
    throw new Error("useTeams must be used within a TeamsProvider");
  }
  return context;
}
