import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

import Interview from "@aglocal/web/schema/Interview";
import { DarkquellInferredSkill } from "@aglocal/web/schema/ModelOutput";
import useTranscript from "@aglocal/web/firebase/queries/useTranscript";
import useReportError from "@aglocal/web/hooks/useReportError";

import Callout from "@aglocal/web/components/Callout";

import Transcript from "@/components/Transcript";

interface TranscriptPanelContentProps {
  interview: Interview;
  skills: readonly DarkquellInferredSkill[];
}

function TranscriptPanelContent({
  interview,
  skills,
}: TranscriptPanelContentProps) {
  const { transcript, loading, error } = useTranscript(interview.id, interview);

  useReportError(error);

  if (error) {
    return <Callout intent="error" title="Error loading transcript" />;
  }

  if (loading) {
    return <Callout intent="info" title="Loading transcript" />;
  }

  if (!transcript?.length) {
    return <Callout intent="warning" title="Transcript not available" />;
  }

  return (
    <Transcript interview={interview} transcript={transcript} skills={skills} />
  );
}

export interface TranscriptAccordionItemProps {
  name: unknown;
  interview: Interview;
  skills: readonly DarkquellInferredSkill[];
}

export default function TranscriptAccordionItem({
  name,
  interview,
  skills,
}: TranscriptAccordionItemProps) {
  return (
    <AccordionItem value={name}>
      <AccordionHeader>Meeting Transcript</AccordionHeader>
      <AccordionPanel>
        <TranscriptPanelContent interview={interview} skills={skills} />
      </AccordionPanel>
    </AccordionItem>
  );
}
