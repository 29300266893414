import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from "react";
import { getApp, initializeApp, } from "firebase/app";
import { FirebaseContext } from "./context";
import invariant from "../helpers/invariant";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function shallowEqual(a, b) {
    return (Object.keys(a).length === Object.keys(b).length &&
        Object.keys(a).every((key) => a[key] === b[key]));
}
export default function FirebaseProvider({ app: providedApp, name, options, children, }) {
    const app = useMemo(() => {
        if (providedApp) {
            return providedApp;
        }
        try {
            const existingApp = getApp(name);
            invariant(shallowEqual(options, existingApp.options), "Firebase app already exists with different config");
            return existingApp;
        }
        catch (error) {
            return initializeApp(options, name);
        }
    }, [providedApp, name, options]);
    return (_jsx(FirebaseContext.Provider, { value: app, children: children }));
}
