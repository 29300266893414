import {
  IPublicClientApplication,
  InteractionRequiredAuthError,
  SilentRequest,
} from "@azure/msal-browser";

import { AuthError, AuthManager } from "@aglocal/web/mission-control";

class MsalAuthManager implements AuthManager {
  private forceRefresh = false;

  constructor(
    private instance: IPublicClientApplication,
    private request: SilentRequest,
  ) {}

  public async getToken(): Promise<string> {
    const accounts = this.instance.getAllAccounts();
    if (accounts.length === 0) {
      throw new AuthError("No accounts");
    }

    try {
      const { idToken } = await this.instance.acquireTokenSilent({
        ...this.request,
        account: accounts[0],
        forceRefresh: this.forceRefresh,
      });
      this.forceRefresh = false;
      return idToken;
    } catch (error) {
      this.forceRefresh = true;
      if (error instanceof InteractionRequiredAuthError) {
        // logout and redirect to login
        await this.instance.logoutRedirect({ onRedirectNavigate: () => false });
      }
      throw error;
    }
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async reset(): Promise<void> {
    this.forceRefresh = true;
  }
}

export default MsalAuthManager;
