export default class HttpAPI {
    constructor(auth) {
        Object.defineProperty(this, "auth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: auth
        });
    }
    async request({ method, url, data, options, retries = 1, }) {
        const token = await this.auth.getToken(options?.signal);
        const headers = { Authorization: `Bearer ${token}` };
        if (data !== undefined) {
            headers["Content-Type"] = "application/json";
        }
        const request = {
            method,
            headers,
            signal: options?.signal,
            body: data !== undefined ? JSON.stringify(data) : undefined,
        };
        const response = await fetch(url, request);
        if (response.ok) {
            return (await response.json());
        }
        // Our token has expired, delete it and try again
        if (response.status === 401) {
            await this.auth.reset();
            if (retries > 0) {
                return await this.request({
                    method,
                    url,
                    data,
                    options,
                    retries: retries - 1,
                });
            }
        }
        // eslint-disable-next-line @typescript-eslint/only-throw-error
        throw response;
    }
}
