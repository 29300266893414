import { Accordion } from "@fluentui/react-components";

import Interview from "@aglocal/web/schema/Interview";
import {
  DarkquellInferredSkill,
  LLMNEREntity,
} from "@aglocal/web/schema/ModelOutput";

import ConnectedDetailsAccordionItem from "./ConnectedDetailsAccordionItem";
import SkillsAccordionItem from "./SkillsAccordionItem";
import TranscriptAccordionItem from "./TranscriptAccordionItem";

export interface SidePanelAccordionProps {
  interview: Interview;
  skills: readonly DarkquellInferredSkill[];
  entities: readonly LLMNEREntity[];
}

export default function SidePanelAccordion({
  interview,
  skills,
  entities,
}: SidePanelAccordionProps) {
  return (
    <Accordion
      defaultOpenItems={["connected-details", "skills", "transcript"]}
      multiple
    >
      <ConnectedDetailsAccordionItem
        name="connected-details"
        interview={interview}
      />
      <SkillsAccordionItem name="skills" skills={skills} entities={entities} />
      <TranscriptAccordionItem
        name="transcript"
        interview={interview}
        skills={skills}
      />
    </Accordion>
  );
}
