import equal from "fast-deep-equal";
import useDedupe from "@aglocal/web/hooks/useDedupe";
import useRealtimeTranscript from "@aglocal/web/firebase/queries/useRealtimeTranscript";
export default function useTranscript(id, interview) {
    const { snapshot, loading, error } = useRealtimeTranscript(id);
    const transcript = useDedupe(snapshot?.data()?.transcript ?? interview?.transcript, equal);
    if (transcript != null) {
        return { transcript, loading: false };
    }
    return { loading, error };
}
