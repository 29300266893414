import invariant from "@aglocal/web/helpers/invariant";

invariant(process.env.PUBLIC_HOSTNAME != null, "PUBLIC_HOSTNAME is not set");
invariant(process.env.MICROSOFT_APP_ID != null, "MICROSOFT_APP_ID is not set");
invariant(
  process.env.MICROSOFT_TENANT_ID != null,
  "MICROSOFT_TENANT_ID is not set",
);

export const GOOGLE_CLOUD_PROJECT = process.env.GOOGLE_CLOUD_PROJECT;
export const GOOGLE_STACKDRIVER_KEY = process.env.GOOGLE_STACKDRIVER_KEY;
export const FIREBASE_API_KEY = process.env.FIREBASE_APPKEY;
export const FIREBASE_APP_ID = process.env.FIREBASE_APPID;
export const FIREBASE_AUTH_DOMAIN = process.env.FIREBASE_AUTHDOMAIN;
export const FIREBASE_DATABASE_URL = process.env.FIREBASE_DATABASEURL;
export const FIREBASE_MEASUREMENT_ID = process.env.FIREBASE_MEASUREMENTID;
export const FIREBASE_MESSAGING_SENDER_ID =
  process.env.FIREBASE_MESSAGINGSENDERID;
export const FIREBASE_PROJECT_ID = process.env.FIREBASE_PROJECTID;
export const FIREBASE_STORAGE_BUCKET = process.env.FIREBASE_STORAGEBUCKET;
export const MICROSOFT_APP_ID = process.env.MICROSOFT_APP_ID;
export const MICROSOFT_TENANT_ID = process.env.MICROSOFT_TENANT_ID;
export const PUBLIC_HOSTNAME = process.env.PUBLIC_HOSTNAME;
export const USE_MOCK_DATA = process.env.USE_MOCK_DATA === "true";
