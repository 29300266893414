import StackdriverErrorReporter from "stackdriver-errors-js";
import asError from "@aglocal/web/helpers/asError";
let errorHandler = null;
export function start(config) {
    if (errorHandler) {
        throw new Error("Error reporter already started");
    }
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start(config);
}
export function setUser(user) {
    if (!errorHandler)
        return;
    errorHandler.setUser(user);
}
export async function report(error, options) {
    if (!errorHandler)
        return;
    await errorHandler.report(asError(error), options);
}
// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() { }
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
if (!window.reportError) {
    window.reportError = function reportError(error) {
        if (errorHandler) {
            errorHandler.report(error).catch(noop);
        }
        else {
            console.error(error);
        }
    };
}
