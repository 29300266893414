import { createContext, useCallback, useContext, useSyncExternalStore, } from "react";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import invariant from "@aglocal/web/helpers/invariant";
export const FirebaseContext = createContext(null);
export function useFirebaseApp() {
    const app = useContext(FirebaseContext);
    invariant(app != null, "useFirebaseApp must be used within a FirebaseProvider");
    return app;
}
export function useFirebaseAuth() {
    return getAuth(useFirebaseApp());
}
export function useFirestore() {
    return getFirestore(useFirebaseApp());
}
export function useFirebaseAnalytics() {
    return getAnalytics(useFirebaseApp());
}
export function useFirebaseUser() {
    const auth = useFirebaseAuth();
    const subscribe = useCallback((onStoreChange) => auth.onAuthStateChanged(onStoreChange), [auth]);
    return useSyncExternalStore(subscribe, () => auth.currentUser);
}
