import teams from "@microsoft/teams-js";

import { AuthManager } from "@aglocal/web/mission-control";


class TeamsAuthManager implements AuthManager {
  public constructor(private request?: teams.authentication.AuthTokenRequestParameters) {}

  public async getToken(): Promise<string> {
    return await teams.authentication.getAuthToken(this.request);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public async reset(): Promise<void> {}
}

export default TeamsAuthManager;
