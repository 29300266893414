import { forwardRef } from "react";
import { renderTypography, useTypography, useTypographyStyles, } from "./styles";
const DEFAULT_HEADING_VARIANTS = {
    h1: "title1",
    h2: "title2",
    h3: "title3",
    h4: "subtitle1",
    h5: "subtitle2",
    h6: "body2",
    p: "body1",
};
export const Heading = forwardRef((props, ref) => {
    const state = useTypography({
        as: "h1",
        block: true,
        // eslint-disable-next-line react/prop-types
        variant: DEFAULT_HEADING_VARIANTS[props.as ?? "h1"],
        ...props,
    }, ref);
    useTypographyStyles(state);
    return renderTypography(state);
});
Heading.displayName = "Heading";
export default Heading;
