import {
  webLightTheme,
  webDarkTheme,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Theme,
} from "@fluentui/react-components";

export type ThemeName = "light" | "dark" | "contrast";

const DEFAULT_THEME: ThemeName = "light";

export function isThemeName(themeName: string): themeName is ThemeName {
  return ["light", "dark", "contrast"].includes(themeName);
}

export function getTheme(name: ThemeName, inTeams?: boolean): Theme {
  switch (name) {
    case "dark":
      return inTeams ? teamsDarkTheme : webDarkTheme;
    case "contrast":
      return teamsHighContrastTheme;
    default:
      return inTeams ? teamsLightTheme : webLightTheme;
  }
}

export interface ThemeState {
  theme: Theme;
  themeName: ThemeName;
}

export function getWindowThemeState(inTeams: boolean): ThemeState {
  const searchParams = new URLSearchParams(window.location.search);
  const themeName = searchParams.get("theme");

  if (themeName && isThemeName(themeName)) {
    return { themeName, theme: getTheme(themeName, inTeams), };
  }

  return { themeName: DEFAULT_THEME, theme: getTheme(DEFAULT_THEME, inTeams) };
}
