import { start } from "@aglocal/web/error-reporting";

import { GOOGLE_CLOUD_PROJECT, GOOGLE_STACKDRIVER_KEY } from "@/config";

if (GOOGLE_CLOUD_PROJECT && GOOGLE_STACKDRIVER_KEY) {
  start({
    key: GOOGLE_STACKDRIVER_KEY,
    projectId: GOOGLE_CLOUD_PROJECT,
    service: "teams-client",
  });
}
