import { jsx as _jsx } from "react/jsx-runtime";
import { TagGroup as FluentUITagGroup, makeStyles, mergeClasses, tokens, } from "@fluentui/react-components";
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexWrap: "wrap",
        gap: tokens.spacingHorizontalS,
    },
});
export default function TagGroup({ className, ...props }) {
    const styles = useStyles();
    return (_jsx(FluentUITagGroup, { className: mergeClasses(styles.root, className), ...props }));
}
