import { ReactNode, useMemo } from "react";

import { app } from "@microsoft/teams-js";

import AuthServices from "./AuthServices";
import TeamsAuthManager from "./TeamsAuthManager";
import { TeamsAuthState } from "./context";

export interface AuthProviderProps {
  context: app.Context;
  children: ReactNode;
}

export default function AuthProvider({ context, children }: AuthProviderProps) {
  const auth = useMemo(() => new TeamsAuthManager(), []);
  const state = useMemo<TeamsAuthState>(
    () => ({
      provider: "teams",
      auth,
      ...context,
    }),
    [auth, context],
  );

  return <AuthServices state={state}>{children}</AuthServices>;
}
