function normalizeName(name) {
    return name
        .toLowerCase()
        .replace(/[^a-z ]/g, "")
        .split(/\s+/g)
        .sort()
        .join(" ");
}
export default function hasName(name, target) {
    return normalizeName(name) === normalizeName(target);
}
