import { ReactNode } from "react";

import { makeStyles, tokens } from "@fluentui/react-components";;

const useStyles = makeStyles({
  root: {
    overflow: "auto",
    minHeight: "100vh",
    backgroundColor: tokens.colorNeutralBackground3,
  },
});

export interface TeamsBackgroundProps {
  children: ReactNode;
}

export default function TeamsBackground({ children }: TeamsBackgroundProps) {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      {children}
    </div>
  );
}
