import { z } from "zod";

import { UpdateConnectedDetails } from "@aglocal/web/mission-control";
import Interview from "@aglocal/web/schema/Interview";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useRef } from "react";

const PostMeetingFormSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  contactId: z.string(),
  userDisplayName: z.string().optional(),
  userSFDCId: z.string().optional(),
  jobTitle: z.string().min(1, { message: "Please enter a job title" }),
  notes: z.string(),
  summary: z.object({
    name: z.string().optional(),
    settings: z
      .object({
        model: z.string().optional(),
        pattern: z.enum(["MAP_REDUCE", "REFINE", "STUFF"]).optional(),
      })
      .optional(),
    value: z.string(),
  }),
  skills: z.array(z.string()),
  meetingType: z.enum(["G2", "STP", "Other"]),
});

export type PostMeetingFormValues = z.infer<typeof PostMeetingFormSchema>;

function useDefaultValues(interview: Interview): PostMeetingFormValues {
  const { id, interviewer, interviewee, inMeetingNotes = "" } = interview;
  const { userId, displayName } = interviewer;
  const { connectedDetails } = interviewee;
  const {
    accountId = "",
    contactId = "",
    candidateStatus,
  } = connectedDetails ?? {};

  return useMemo(
    () => ({
      id,
      accountId,
      contactId,
      userDisplayName: displayName,
      userSFDCId: userId,
      notes: inMeetingNotes,
      meetingType: candidateStatus === "Current" ? "STP" : "G2",
      jobTitle: "",
      summary: { value: "" },
      skills: [],
    }),
    [
      id,
      accountId,
      contactId,
      candidateStatus,
      displayName,
      inMeetingNotes,
      userId,
    ],
  );
}

export function usePostMeetingForm(interview: Interview) {
  const defaultValues = useDefaultValues(interview);

  const form = useForm({
    defaultValues,
    resolver: zodResolver(PostMeetingFormSchema),
  });

  const idRef = useRef(interview.id);
  const { reset } = form;

  useEffect(() => {
    reset(defaultValues, { keepDirtyValues: idRef.current === interview.id });
    idRef.current = interview.id;
  }, [idRef, interview.id, defaultValues, reset]);

  return form;
}

function formatG2Notes(notes: string, summary: string): string {
  if (summary.trim() === "") {
    return notes;
  }

  if (notes.trim() === "") {
    return summary;
  }

  return `${notes}\n\nMeeting Summary:\n${summary}`;
}

export function getConnectedDetailsUpdate({
  id,
  notes,
  summary,
  ...data
}: PostMeetingFormValues): UpdateConnectedDetails {
  return {
    id,
    details: {
      ...data,
      iId: id,
      g2Notes: formatG2Notes(notes, summary.value),
    },
  };
}
