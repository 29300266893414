import { useEffect, useState } from "react";
import { getCountFromServer, getDoc, getDocs, onSnapshot, queryEqual, refEqual, } from "firebase/firestore";
import useDedupe from "@aglocal/web/hooks/useDedupe";
import useAsync from "@aglocal/web/hooks/useAsync";
function asyncToSnapshot({ result, loading, error, }) {
    return { snapshot: result, loading, error };
}
export function asType() {
    return {
        toFirestore(data) {
            return data;
        },
        fromFirestore(snapshot, options) {
            return snapshot.data(options);
        },
    };
}
export function useDocOnce(reference) {
    const referenceMemo = useDedupe(reference, refEqual);
    return asyncToSnapshot(useAsync(() => getDoc(referenceMemo), [referenceMemo]));
}
export function useDocsOnce(query) {
    const queryMemo = useDedupe(query, queryEqual);
    return asyncToSnapshot(useAsync(() => getDocs(queryMemo), [queryMemo]));
}
export function useDoc(reference, options) {
    const referenceMemo = useDedupe(reference, refEqual);
    const [state, setState] = useState({ loading: true });
    useEffect(() => {
        return onSnapshot(referenceMemo, options ?? {}, {
            next: (snapshot) => {
                setState({ loading: false, snapshot });
            },
            error: (error) => {
                setState({ loading: false, error });
            },
        });
    }, [setState, referenceMemo, options]);
    return state;
}
export function useDocs(query, options) {
    const queryMemo = useDedupe(query, queryEqual);
    const [state, setState] = useState({ loading: true });
    useEffect(() => {
        return onSnapshot(queryMemo, options ?? {}, {
            next: (snapshot) => {
                setState({ loading: false, snapshot });
            },
            error: (error) => {
                setState({ loading: false, error });
            },
        });
    }, [setState, queryMemo, options]);
    return state;
}
export function useCount(query) {
    const queryMemo = useDedupe(query, queryEqual);
    return asyncToSnapshot(useAsync(() => getCountFromServer(queryMemo), [queryMemo]));
}
