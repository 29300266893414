export const SCREEN_MIN_SM = 320;
export const SCREEN_MIN_MD = 480;
export const SCREEN_MIN_LG = 640;
export const SCREEN_MIN_XL = 1024;
export const SCREEN_MIN_XXL = 1366;
export const SCREEN_MIN_XXXL = 1920;
// Maximum widths for each breakpoint. These are set to values like 479.99999px to prevent an issue where a browser's
// width may not be a whole pixel value, creating the potential for multiple media queries to apply simultaneously.
// See: http://damienclarke.me/code/posts/those-1px-gaps-between-media-queries-can-be-a-problem
export const SCREEN_MAX_SM = SCREEN_MIN_MD - 0.00001;
export const SCREEN_MAX_MD = SCREEN_MIN_LG - 0.00001;
export const SCREEN_MAX_LG = SCREEN_MIN_XL - 0.00001;
export const SCREEN_MAX_XL = SCREEN_MIN_XXL - 0.00001;
export const SCREEN_MAX_XXL = SCREEN_MIN_XXXL - 0.00001;
export const MIN_SCREEN_SIZES = {
    sm: SCREEN_MIN_SM,
    md: SCREEN_MIN_MD,
    lg: SCREEN_MIN_LG,
    xl: SCREEN_MIN_XL,
    xxl: SCREEN_MIN_XXL,
    xxxl: SCREEN_MIN_XXXL,
};
export const MAX_SCREEN_SIZES = {
    sm: SCREEN_MAX_SM,
    md: SCREEN_MAX_MD,
    lg: SCREEN_MAX_LG,
    xl: SCREEN_MAX_XL,
    xxl: SCREEN_MAX_XXL,
    xxxl: Infinity,
};
export function mediaQuery(options) {
    if (!("min" in options)) {
        return `@media (max-width: ${MAX_SCREEN_SIZES[options.max]}px)`;
    }
    if (!("max" in options)) {
        return `@media (min-width: ${MIN_SCREEN_SIZES[options.min]}px)`;
    }
    const min = MIN_SCREEN_SIZES[options.min];
    const max = MAX_SCREEN_SIZES[options.max];
    return `@media (min-width: ${min}px) and (max-width: ${max}px)`;
}
