import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, makeStyles, mergeClasses, slot, } from "@fluentui/react-components";
import spacing from "@aglocal/web/helpers/spacing";
const GAP_VARIABLE = "--ag-stack--gap";
const GAP_TOKEN = `var(${GAP_VARIABLE})`;
const VERTICAL_ALIGN_VARIABLE = "--ag-stack--vertical-alignment";
const VERTICAL_ALIGN_TOKEN = `var(${VERTICAL_ALIGN_VARIABLE})`;
const HORIZONTAL_ALIGN_VARIABLE = "--ag-stack--horizontal-alignment";
const HORIZONTAL_ALIGN_TOKEN = `var(${HORIZONTAL_ALIGN_VARIABLE})`;
const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        width: "auto",
        height: "auto",
        boxSizing: "border-box",
        gap: GAP_TOKEN,
        alignItems: HORIZONTAL_ALIGN_TOKEN,
        justifyContent: VERTICAL_ALIGN_TOKEN,
        "> *": {
            textOverflow: "ellipsis",
        },
        "> :not(:first-child)": {
            marginTop: "0px",
        },
        "> *:not(.ag-StackItem)": {
            flexShrink: 1,
        },
    },
    disableShrink: {
        "> *": {
            flexShrink: 0,
        },
    },
    grow: {
        "> *": {
            flexGrow: 1,
        },
    },
    horizontal: {
        flexDirection: "row",
        alignItems: VERTICAL_ALIGN_TOKEN,
        justifyContent: HORIZONTAL_ALIGN_TOKEN,
    },
    verticalReverse: {
        flexDirection: "column-reverse",
    },
    horizontalReverse: {
        flexDirection: "row-reverse",
    },
    verticalFill: {
        height: "100%",
    },
});
function useStack({ disableShrink = false, gap = "none", horizontal = false, verticalAlign = null, horizontalAlign = null, reverse = false, verticalFill = false, ...props }, ref) {
    return {
        disableShrink,
        gap,
        horizontal,
        verticalAlign,
        horizontalAlign,
        reverse,
        verticalFill,
        components: { root: "div" },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useStackStyles(state) {
    const styles = useStyles();
    state.root.style = state.root.style ?? {};
    state.root.style[GAP_VARIABLE] = spacing(state.gap, state.horizontal ? "horizontal" : "vertical");
    if (state.horizontalAlign != null) {
        state.root.style[HORIZONTAL_ALIGN_VARIABLE] = state.horizontalAlign;
    }
    if (state.verticalAlign != null) {
        state.root.style[VERTICAL_ALIGN_VARIABLE] = state.verticalAlign;
    }
    state.root.className = mergeClasses("ag-Stack", styles.root, state.disableShrink && styles.disableShrink, state.horizontal && styles.horizontal, state.reverse &&
        (state.horizontal ? styles.horizontalReverse : styles.verticalReverse), state.verticalFill && styles.verticalFill, state.root.className);
}
function renderStack(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Stack = forwardRef((props, ref) => {
    const state = useStack(props, ref);
    useStackStyles(state);
    return renderStack(state);
});
Stack.displayName = "Stack";
export default Stack;
