import { logEvent } from "firebase/analytics";

import {
  Link,
  makeStyles,
  mergeClasses,
  Spinner,
  Toast,
  Toaster,
  ToastTitle,
  ToastTrigger,
  tokens,
  useId,
  useToastController,
} from "@fluentui/react-components";

import Interview from "@aglocal/web/schema/Interview";
import { mediaQuery } from "@aglocal/web/helpers/responsive";
import { useMissionControl } from "@aglocal/web/mission-control";

import Container from "@aglocal/web/components/Container";
import { Heading } from "@aglocal/web/components/Typography";

import { useFirebaseAnalytics } from "@aglocal/web/firebase";
import useDarkquell from "@aglocal/web/firebase/queries/useDarkquell";
import useLLMNER from "@aglocal/web/firebase/queries/useLLMNER";
import asError from "@aglocal/web/helpers/asError";
import useReportError from "@aglocal/web/hooks/useReportError";

import useSetDefault from "@/hooks/useSetDefault";

import LoadingPage from "@/pages/Loading";
import ErrorPage from "@/pages/Error";

import { getConnectedDetailsUpdate, usePostMeetingForm } from "./form";
import PostMeetingForm from "./PostMeetingForm";
import PostMeetingAccordion from "./PostMeetingAccordion";
import PostMeetingFooter from "./PostMeetingFooter";

const MEDIA_GRID = mediaQuery({ min: "xl" });
const MEDIA_FLUID = mediaQuery({ max: "lg" });

const useStyles = makeStyles({
  root: {
    [MEDIA_GRID]: {
      height: "100vh",
      display: "grid",
      overflow: "hidden",
      gridTemplateAreas: `
        "header"
        "main"
        "footer"
      `,
      gridTemplateRows: "auto minmax(0, 1fr) auto",
    },
  },
  header: {
    overflow: "auto",
    gridArea: "header",
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
  },
  main: {
    gridArea: "main",
    [MEDIA_GRID]: {
      display: "grid",
      overflow: "hidden",
      gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
      gridTemplateRows: "minmax(0, 1fr)",
    },
  },
  section: {
    [MEDIA_GRID]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  sectionContent: {
    [MEDIA_GRID]: {
      flexGrow: 1,
      overflowX: "hidden",
      overflowY: "auto",
      paddingBottom: tokens.spacingVerticalM,
      paddingRight: tokens.spacingHorizontalM,
    },
  },
  footer: {
    gridArea: "footer",
    [MEDIA_FLUID]: {
      position: "sticky",
      bottom: 0,
    },
  },
});

function ProgressToast() {
  return (
    <Toast>
      <ToastTitle media={<Spinner size="tiny" />}>
        Updating Connected...
      </ToastTitle>
    </Toast>
  );
}

function DismissibleToast({ message }: { message: string }) {
  return (
    <Toast>
      <ToastTitle
        action={
          <ToastTrigger>
            <Link as="button">Dismiss</Link>
          </ToastTrigger>
        }
      >
        {message}
      </ToastTitle>
    </Toast>
  );
}

export interface PostMeetingTabProps {
  interview: Interview;
}

export default function PostMeetingTab({ interview }: PostMeetingTabProps) {
  const styles = useStyles();

  const api = useMissionControl();
  const analytics = useFirebaseAnalytics();

  const submitToastId = useId("toast-submit-");
  const toasterId = useId("toaster-");
  const { dispatchToast, updateToast } = useToastController(toasterId);

  const {
    control,
    getFieldState,
    getValues,
    handleSubmit,
    resetField,
    setError,
    setValue,
  } = usePostMeetingForm(interview);

  const setDefault = useSetDefault({
    getFieldState,
    getValues,
    resetField,
    setValue,
  });

  const [skills, darkquellLoading, darkquellError] = useDarkquell(interview.id);
  const [entities, llmNerLoading, llmNerError] = useLLMNER(interview.id);

  useReportError(darkquellError);
  useReportError(llmNerError);

  const loading = darkquellLoading || llmNerLoading;
  const error = darkquellError ?? llmNerError;

  if (interview.interviewee.connectedDetails == null) {
    return <ErrorPage title="Connected Details Not Found" />;
  }

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorPage title="Error loading meeting" />;
  }

  const onSubmit = handleSubmit(async (data) => {
    if (data.notes.trim() === "" && data.summary.value.trim() === "") {
      const error = {
        type: "required",
        message: "Please enter a meeting summary or notes",
      };

      setError("notes", error);
      setError("summary", error);

      return;
    }

    try {
      logEvent(analytics, "meeting_summary_submitted", {
        iid: interview.id,
        name: data.summary.name,
        settings: data.summary.settings,
        edited: getFieldState("summary.value").isDirty,
      });
    } catch (error) {
      reportError(error);
    }

    dispatchToast(<ProgressToast />, {
      toastId: submitToastId,
      timeout: -1,
      position: "bottom",
    });

    try {
      const update = getConnectedDetailsUpdate(data);
      await api.updateConnectedDetails(update);
      updateToast({
        content: <DismissibleToast message="Connected Updated" />,
        toastId: submitToastId,
        intent: "success",
      });
    } catch (error: unknown) {
      updateToast({
        content: <DismissibleToast message="Error Updating Connected" />,
        toastId: submitToastId,
        intent: "error",
      });
      reportError(asError(error));
    }
  });

  return (
    <form
      className={mergeClasses(styles.root)}
      onSubmit={(event) => {
        void onSubmit(event);
      }}
    >
      <header className={styles.header}>
        <Container>
          <Heading variant="subtitle1">
            Post meeting conversation
            {interview.interviewee.connectedDetails.displayName &&
              ` with ${interview.interviewee.connectedDetails.displayName}`}
          </Heading>
        </Container>
      </header>

      <Container as="main" className={styles.main}>
        <section className={styles.section}>
          <Heading as="h2" variant="subtitle2">
            Details to Update in Connected
          </Heading>
          <div className={styles.sectionContent}>
            <PostMeetingForm
              interview={interview}
              skills={skills}
              entities={entities}
              control={control}
              getFieldState={getFieldState}
              getValues={getValues}
              resetField={resetField}
              setDefault={setDefault}
              setValue={setValue}
            />
          </div>
        </section>
        <section className={styles.section}>
          <Heading as="h2" variant="subtitle2">
            Details Gathered During Meeting
          </Heading>
          <div className={styles.sectionContent}>
            <PostMeetingAccordion
              interview={interview}
              skills={skills}
              entities={entities}
            />
          </div>
        </section>
      </Container>

      <PostMeetingFooter className={styles.footer} control={control} />
      <Toaster toasterId={toasterId} />
    </form>
  );
}
