import { ReactNode, useEffect } from "react";

import { setUser } from "@aglocal/web/error-reporting";
import { MissionControlProvider } from "@aglocal/web/mission-control";
import { FirebaseProvider } from "@aglocal/web/firebase";

import { FIREBASE_OPTIONS } from "@/firebase";
import getUserId from "@/helpers/getUserId";

import { AuthContext, AuthState } from "./context";
import FirebaseAuthProvider from "./FirebaseAuthProvider";

export interface AuthServicesProps {
  state: AuthState;
  children: ReactNode;
}

export default function AuthServices({ state, children }: AuthServicesProps) {
  const userId = getUserId(state);

  useEffect(() => {
    setUser(userId);
    return () => {
      setUser();
    };
  }, [userId]);

  return (
    <AuthContext.Provider value={state}>
      <MissionControlProvider auth={state.auth}>
        <FirebaseProvider options={FIREBASE_OPTIONS}>
          <FirebaseAuthProvider>{children}</FirebaseAuthProvider>
        </FirebaseProvider>
      </MissionControlProvider>
    </AuthContext.Provider>
  );
}
