import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

import useReportError from "@aglocal/web/hooks/useReportError";

import Interview from "@aglocal/web/schema/Interview";
import useDarkquell from "@aglocal/web/firebase/queries/useDarkquell";
import useLLMNER from "@aglocal/web/firebase/queries/useLLMNER";

import Callout from "@aglocal/web/components/Callout";

import SkillPills from "@/components/SkillPills";

export interface SkillsAccordionItemProps {
  name: unknown;
  interview: Interview;
}

export default function SkillsAccordionItem({
  name,
  interview,
}: SkillsAccordionItemProps) {
  const [skills,, darkquellError] = useDarkquell(interview.id);
  const [entities,, llmNerError] = useLLMNER(interview.id);
  const error = darkquellError ?? llmNerError;

  useReportError(darkquellError);
  useReportError(llmNerError);

  return (
    <AccordionItem value={name}>
      <AccordionHeader>Skills, Job Titles, Organizations</AccordionHeader>
      <AccordionPanel>
        {error && <Callout intent="error" title="Error loading skills" />}
        {skills.length === 0 && entities.length === 0 ? (
          <Callout intent="warning" title="No skills found" />
        ) : (
          <SkillPills skills={skills} entities={entities} />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}
