import { tokens } from "@fluentui/react-components";
const VERTICAL_TOKENS = {
    none: tokens.spacingVerticalNone,
    xxs: tokens.spacingVerticalXXS,
    xs: tokens.spacingVerticalXS,
    s: tokens.spacingVerticalS,
    m: tokens.spacingVerticalM,
    l: tokens.spacingVerticalL,
    xl: tokens.spacingVerticalXL,
    xxl: tokens.spacingVerticalXXL,
    xxxl: tokens.spacingVerticalXXXL,
};
const HORIZONTAL_TOKENS = {
    none: tokens.spacingHorizontalNone,
    xxs: tokens.spacingHorizontalXXS,
    xs: tokens.spacingHorizontalXS,
    s: tokens.spacingHorizontalS,
    m: tokens.spacingHorizontalM,
    l: tokens.spacingHorizontalL,
    xl: tokens.spacingHorizontalXL,
    xxl: tokens.spacingHorizontalXXL,
    xxxl: tokens.spacingHorizontalXXXL,
};
export default function spacing(size, orientation) {
    const tokens = orientation === "vertical" ? VERTICAL_TOKENS : HORIZONTAL_TOKENS;
    return tokens[size];
}
