import { Fragment, useMemo } from "react";

import {
  makeStyles,
  mergeClasses,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  shorthands,
  Tag,
  TagGroup,
  tokens,
  typographyStyles,
} from "@fluentui/react-components";

import escapeRegExp from "@aglocal/web/helpers/escapeRegExp";
import hasName from "@aglocal/web/helpers/hasName";
import UniqueList from "@aglocal/web/helpers/UniqueList";

import {
  DarkquellInferredSkill,
  DarkquellSkill,
} from "@aglocal/web/schema/ModelOutput";

import type { Transcript as TranscriptType } from "@aglocal/web/schema/RealtimeTranscript";

import Stack from "@aglocal/web/components/Stack";
import { Heading, Span } from "@aglocal/web/components/Typography";
import Interview from "@aglocal/web/schema/Interview";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: tokens.spacingVerticalXS,
    marginBottom: tokens.spacingVerticalS,
  },
  interviewerContainer: {
    alignItems: "flex-start",
  },
  intervieweeContainer: {
    alignItems: "flex-end",
  },
  speaker: {
    ...typographyStyles.caption1,
    ...shorthands.padding(
      tokens.spacingVerticalNone,
      tokens.spacingHorizontalM,
    ),
    color: tokens.colorNeutralForeground2,
  },
  text: {
    ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
    borderRadius: "0.5rem",
    maxWidth: "90%",
  },
  interviewerText: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
  intervieweeText: {
    backgroundColor: tokens.colorBrandBackground2,
    float: "right",
  },
  popover: {
    maxWidth: "36rem",
  },
  popoverHeader: {
    marginTop: 0,
    marginBottom: tokens.spacingVerticalS,
  },
});

interface SearchPart {
  text: string;
  skills?: UniqueList<DarkquellSkill>;
}

class SkillIndex {
  private index = new Map<string, UniqueList<DarkquellSkill>>();
  private pattern: RegExp;

  constructor(skills: readonly DarkquellInferredSkill[]) {
    skills.forEach(({ skill, sources }) => {
      sources.forEach((source) => {
        let skills = this.index.get(source.text);
        if (skills == null) {
          skills = new UniqueList(undefined, ({ id }) => id);
          this.index.set(source.text, skills);
        }
        skills.add(skill);
      });
    });
    this.pattern = new RegExp(
      [...this.index.keys()].map(escapeRegExp).join("|"),
      "gi",
    );
  }

  search(value: string): SearchPart[] {
    const results: SearchPart[] = [];

    let position = 0;

    for (const match of value.matchAll(this.pattern)) {
      if (match.index > position) {
        results.push({ text: value.slice(position, match.index) });
      }

      results.push({ text: match[0], skills: this.index.get(match[0]) });
      position = match.index + match[0].length;
    }

    if (position < value.length) {
      results.push({ text: value.slice(position) });
    }

    return results;
  }
}

interface TextWithSkillsProps {
  text: string;
  skillIndex?: SkillIndex;
}

function TextWithSkills({ text, skillIndex }: TextWithSkillsProps) {
  const styles = useStyles();
  const parts = useMemo(
    () => (skillIndex ? skillIndex.search(text) : [{ text }]),
    [text, skillIndex],
  );

  return (
    <>
      {parts.map(({ text, skills }, index) =>
        skills?.length ? (
          <Popover key={index} openOnHover withArrow inline mouseLeaveDelay={0}>
            <PopoverTrigger disableButtonEnhancement>
              <span>
                {" "}
                <Span underline>{text}</Span>{" "}
              </span>
            </PopoverTrigger>
            <PopoverSurface tabIndex={-1}>
              <Heading
                as="h3"
                variant="subtitle2"
                className={styles.popoverHeader}
              >
                Skills found:
              </Heading>
              <TagGroup>
                {skills.map((skill) => (
                  <Tag key={skill.id}>{skill.name}</Tag>
                ))}
              </TagGroup>
            </PopoverSurface>
          </Popover>
        ) : (
          <Fragment key={index}>{text}</Fragment>
        ),
      )}
    </>
  );
}

interface TranscriptBlock {
  speaker: string | null;
  text: string;
  isInterviewer: boolean;
}

interface TranscriptItemProps extends TranscriptBlock {
  skillIndex?: SkillIndex;
}

function TranscriptItem({
  isInterviewer,
  speaker,
  text,
  skillIndex,
}: TranscriptItemProps) {
  const styles = useStyles();

  return (
    <div
      className={mergeClasses(
        styles.container,
        isInterviewer
          ? styles.interviewerContainer
          : styles.intervieweeContainer,
      )}
    >
      {speaker && <div className={styles.speaker}>{speaker}</div>}
      <div
        className={mergeClasses(
          styles.text,
          isInterviewer ? styles.interviewerText : styles.intervieweeText,
        )}
      >
        <TextWithSkills text={text} skillIndex={skillIndex} />
      </div>
    </div>
  );
}

function isInterviewer(speaker: string | null, interview: Interview): boolean {
  return interview.interviewer.displayName && speaker
    ? hasName(speaker, interview.interviewer.displayName)
    : false;
}

export interface TranscriptProps {
  interview: Interview;
  transcript: TranscriptType;
  skills?: readonly DarkquellInferredSkill[];
}

export default function Transcript({
  interview,
  transcript,
  skills,
}: TranscriptProps) {
  const skillIndex = useMemo(() => skills && new SkillIndex(skills), [skills]);
  const formatted = useMemo<TranscriptBlock[]>(() => {
    const combined: TranscriptType = [];

    for (const { speaker, words } of transcript) {
      const previousSpeaker = combined.length
        ? combined[combined.length - 1].speaker
        : null;

      if (previousSpeaker == null || previousSpeaker !== speaker) {
        combined.push({ speaker, words: [] });
      }

      combined[combined.length - 1].words.push(...words);
    }

    return combined.map(({ speaker = null, words }) => ({
      speaker,
      text: words.map(({ text }) => text).join(" "),
      isInterviewer: isInterviewer(speaker, interview),
    }));
  }, [interview, transcript]);
  return (
    <Stack gap="s">
      {formatted.map((item, index) => (
        <TranscriptItem key={index} skillIndex={skillIndex} {...item} />
      ))}
    </Stack>
  );
}
