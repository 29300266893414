import { useCallback, useEffect, useMemo, useState } from "react";

import { app, pages } from "@microsoft/teams-js";
import { FluentProvider } from "@fluentui/react-components";

import FluentBodyTheme from "@aglocal/web/components/FluentBodyTheme";

import { AppState, TeamsContext, TeamsState } from "./context";
import {
  ThemeName,
  ThemeState,
  getWindowThemeState,
  getTheme,
  isThemeName,
} from "./theme";

export interface TeamsProviderProps {
  children: React.ReactNode;
}

export default function TeamsProvider({ children }: TeamsProviderProps) {
  const [systemTheme, setSystemTheme] = useState<ThemeState>(() =>
    getWindowThemeState(true),
  );
  const [userTheme, setUserTheme] = useState<ThemeState | null>(null);
  const [appState, setAppState] = useState<AppState>({
    loading: true,
    inTeams: undefined,
  });

  const setTheme = useCallback(
    (themeName: ThemeName | null) => {
      setUserTheme(
        themeName && {
          themeName,
          theme: getTheme(themeName, appState.inTeams),
        },
      );
    },
    [appState.inTeams],
  );

  useEffect(() => {
    app
      .initialize()
      .then(() =>
        app.getContext().then((context) => {
          setAppState({
            loading: false,
            inTeams: true,
            isFullScreen: context.page.isFullScreen,
            context,
          });

          app.registerOnThemeChangeHandler((themeName) => {
            if (!isThemeName(themeName)) {
              return;
            }
            setSystemTheme({
              themeName,
              theme: getTheme(themeName, true),
            });
          });

          pages.registerFullScreenHandler((isFullScreen) => {
            setAppState((current) => {
              if (current.loading || !current.inTeams) {
                return current;
              }
              return { ...current, isFullScreen };
            });
          });

          app.notifySuccess();
        }),
      )
      .catch(() => {
        setSystemTheme(getWindowThemeState(false));
        setAppState({
          loading: false,
          inTeams: false,
        });
      });
  }, []);

  const state: TeamsState = useMemo(
    () => ({
      ...appState,
      ...systemTheme,
      ...userTheme,
      setTheme,
    }),
    [appState, systemTheme, userTheme, setTheme],
  );

  return (
    <TeamsContext.Provider value={state}>
      <FluentProvider theme={state.theme}>
        <FluentBodyTheme />
        {children}
      </FluentProvider>
    </TeamsContext.Provider>
  );
}
