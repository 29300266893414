import { createContext, useContext } from "react";

import { app } from "@microsoft/teams-js";
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";

import TeamsAuthManager from "./TeamsAuthManager";
import MsalAuthManager from "./MsalAuthManager";

export interface TeamsAuthState extends app.Context {
  provider: "teams";
  auth: TeamsAuthManager;
}

export interface MslaAuthState {
  provider: "msal";
  auth: MsalAuthManager;
  instance: IPublicClientApplication;
  account: AccountInfo;
}

export type AuthState = TeamsAuthState | MslaAuthState;

export const AuthContext = createContext<AuthState | null>(null);

export function useAuth(): AuthState {
  const state = useContext(AuthContext);
  if (state == null) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return state;
}
