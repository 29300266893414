import { ReactNode } from "react";

import Callout from "@aglocal/web/components/Callout";
import RetryAction from "@aglocal/web/components/RetryAction";
import TeamsBackground from "@/components/TeamsBackground";

export interface ErrorPageProps {
  title: string;
  children?: ReactNode;
}

export default function ErrorPage(props: ErrorPageProps) {
  return (
    <TeamsBackground>
      <Callout intent="error" actions={<RetryAction />} {...props} />
    </TeamsBackground>
  );
}
