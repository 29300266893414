import { useEffect, useReducer, useState } from "react";
import asError from "@aglocal/web/helpers/asError";
export default function useAsync(callback, deps = []) {
    const [refreshCount, refresh] = useReducer((count) => count + 1, 0);
    const [state, setState] = useState({ loading: true });
    useEffect(() => {
        const controller = new AbortController();
        setState({ loading: true });
        callback(controller.signal).then((result) => {
            if (controller.signal.aborted)
                return;
            setState({ loading: false, result });
        }, (error) => {
            if (controller.signal.aborted)
                return;
            setState({ loading: false, error: asError(error) });
        });
        return () => {
            controller.abort();
        };
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshCount, ...deps]);
    return { ...state, refresh };
}
