import { useEffect } from "react";
import { useThemeClassName } from "@fluentui/react-components";
export default function FluentBodyTheme() {
    const className = useThemeClassName();
    useEffect(() => {
        if (className.trim() === "") {
            return;
        }
        const classList = className.split(" ");
        document.body.classList.add(...classList);
        return () => {
            document.body.classList.remove(...classList);
        };
    }, [className]);
    return null;
}
