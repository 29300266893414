export var HTTPStatus;
(function (HTTPStatus) {
    // informational
    HTTPStatus[HTTPStatus["CONTINUE"] = 100] = "CONTINUE";
    HTTPStatus[HTTPStatus["SWITCHING_PROTOCOLS"] = 101] = "SWITCHING_PROTOCOLS";
    HTTPStatus[HTTPStatus["PROCESSING"] = 102] = "PROCESSING";
    HTTPStatus[HTTPStatus["EARLY_HINTS"] = 103] = "EARLY_HINTS";
    // success
    HTTPStatus[HTTPStatus["OK"] = 200] = "OK";
    HTTPStatus[HTTPStatus["CREATED"] = 201] = "CREATED";
    HTTPStatus[HTTPStatus["ACCEPTED"] = 202] = "ACCEPTED";
    HTTPStatus[HTTPStatus["NON_AUTHORITATIVE_INFORMATION"] = 203] = "NON_AUTHORITATIVE_INFORMATION";
    HTTPStatus[HTTPStatus["NO_CONTENT"] = 204] = "NO_CONTENT";
    HTTPStatus[HTTPStatus["RESET_CONTENT"] = 205] = "RESET_CONTENT";
    HTTPStatus[HTTPStatus["PARTIAL_CONTENT"] = 206] = "PARTIAL_CONTENT";
    HTTPStatus[HTTPStatus["MULTI_STATUS"] = 207] = "MULTI_STATUS";
    HTTPStatus[HTTPStatus["ALREADY_REPORTED"] = 208] = "ALREADY_REPORTED";
    HTTPStatus[HTTPStatus["IM_USED"] = 226] = "IM_USED";
    // redirection
    HTTPStatus[HTTPStatus["MULTIPLE_CHOICES"] = 300] = "MULTIPLE_CHOICES";
    HTTPStatus[HTTPStatus["MOVED_PERMANENTLY"] = 301] = "MOVED_PERMANENTLY";
    HTTPStatus[HTTPStatus["FOUND"] = 302] = "FOUND";
    HTTPStatus[HTTPStatus["SEE_OTHER"] = 303] = "SEE_OTHER";
    HTTPStatus[HTTPStatus["NOT_MODIFIED"] = 304] = "NOT_MODIFIED";
    HTTPStatus[HTTPStatus["USE_PROXY"] = 305] = "USE_PROXY";
    HTTPStatus[HTTPStatus["TEMPORARY_REDIRECT"] = 307] = "TEMPORARY_REDIRECT";
    HTTPStatus[HTTPStatus["PERMANENT_REDIRECT"] = 308] = "PERMANENT_REDIRECT";
    // client error
    HTTPStatus[HTTPStatus["BAD_REQUEST"] = 400] = "BAD_REQUEST";
    HTTPStatus[HTTPStatus["UNAUTHORIZED"] = 401] = "UNAUTHORIZED";
    HTTPStatus[HTTPStatus["PAYMENT_REQUIRED"] = 402] = "PAYMENT_REQUIRED";
    HTTPStatus[HTTPStatus["FORBIDDEN"] = 403] = "FORBIDDEN";
    HTTPStatus[HTTPStatus["NOT_FOUND"] = 404] = "NOT_FOUND";
    HTTPStatus[HTTPStatus["METHOD_NOT_ALLOWED"] = 405] = "METHOD_NOT_ALLOWED";
    HTTPStatus[HTTPStatus["NOT_ACCEPTABLE"] = 406] = "NOT_ACCEPTABLE";
    HTTPStatus[HTTPStatus["PROXY_AUTHENTICATION_REQUIRED"] = 407] = "PROXY_AUTHENTICATION_REQUIRED";
    HTTPStatus[HTTPStatus["REQUEST_TIMEOUT"] = 408] = "REQUEST_TIMEOUT";
    HTTPStatus[HTTPStatus["CONFLICT"] = 409] = "CONFLICT";
    HTTPStatus[HTTPStatus["GONE"] = 410] = "GONE";
    HTTPStatus[HTTPStatus["LENGTH_REQUIRED"] = 411] = "LENGTH_REQUIRED";
    HTTPStatus[HTTPStatus["PRECONDITION_FAILED"] = 412] = "PRECONDITION_FAILED";
    HTTPStatus[HTTPStatus["REQUEST_ENTITY_TOO_LARGE"] = 413] = "REQUEST_ENTITY_TOO_LARGE";
    HTTPStatus[HTTPStatus["REQUEST_URI_TOO_LONG"] = 414] = "REQUEST_URI_TOO_LONG";
    HTTPStatus[HTTPStatus["UNSUPPORTED_MEDIA_TYPE"] = 415] = "UNSUPPORTED_MEDIA_TYPE";
    HTTPStatus[HTTPStatus["REQUESTED_RANGE_NOT_SATISFIABLE"] = 416] = "REQUESTED_RANGE_NOT_SATISFIABLE";
    HTTPStatus[HTTPStatus["EXPECTATION_FAILED"] = 417] = "EXPECTATION_FAILED";
    HTTPStatus[HTTPStatus["IM_A_TEAPOT"] = 418] = "IM_A_TEAPOT";
    HTTPStatus[HTTPStatus["MISDIRECTED_REQUEST"] = 421] = "MISDIRECTED_REQUEST";
    HTTPStatus[HTTPStatus["UNPROCESSABLE_ENTITY"] = 422] = "UNPROCESSABLE_ENTITY";
    HTTPStatus[HTTPStatus["LOCKED"] = 423] = "LOCKED";
    HTTPStatus[HTTPStatus["FAILED_DEPENDENCY"] = 424] = "FAILED_DEPENDENCY";
    HTTPStatus[HTTPStatus["TOO_EARLY"] = 425] = "TOO_EARLY";
    HTTPStatus[HTTPStatus["UPGRADE_REQUIRED"] = 426] = "UPGRADE_REQUIRED";
    HTTPStatus[HTTPStatus["PRECONDITION_REQUIRED"] = 428] = "PRECONDITION_REQUIRED";
    HTTPStatus[HTTPStatus["TOO_MANY_REQUESTS"] = 429] = "TOO_MANY_REQUESTS";
    HTTPStatus[HTTPStatus["REQUEST_HEADER_FIELDS_TOO_LARGE"] = 431] = "REQUEST_HEADER_FIELDS_TOO_LARGE";
    HTTPStatus[HTTPStatus["UNAVAILABLE_FOR_LEGAL_REASONS"] = 451] = "UNAVAILABLE_FOR_LEGAL_REASONS";
    // server errors
    HTTPStatus[HTTPStatus["INTERNAL_SERVER_ERROR"] = 500] = "INTERNAL_SERVER_ERROR";
    HTTPStatus[HTTPStatus["NOT_IMPLEMENTED"] = 501] = "NOT_IMPLEMENTED";
    HTTPStatus[HTTPStatus["BAD_GATEWAY"] = 502] = "BAD_GATEWAY";
    HTTPStatus[HTTPStatus["SERVICE_UNAVAILABLE"] = 503] = "SERVICE_UNAVAILABLE";
    HTTPStatus[HTTPStatus["GATEWAY_TIMEOUT"] = 504] = "GATEWAY_TIMEOUT";
    HTTPStatus[HTTPStatus["HTTP_VERSION_NOT_SUPPORTED"] = 505] = "HTTP_VERSION_NOT_SUPPORTED";
    HTTPStatus[HTTPStatus["VARIANT_ALSO_NEGOTIATES"] = 506] = "VARIANT_ALSO_NEGOTIATES";
    HTTPStatus[HTTPStatus["INSUFFICIENT_STORAGE"] = 507] = "INSUFFICIENT_STORAGE";
    HTTPStatus[HTTPStatus["LOOP_DETECTED"] = 508] = "LOOP_DETECTED";
    HTTPStatus[HTTPStatus["NOT_EXTENDED"] = 510] = "NOT_EXTENDED";
    HTTPStatus[HTTPStatus["NETWORK_AUTHENTICATION_REQUIRED"] = 511] = "NETWORK_AUTHENTICATION_REQUIRED";
})(HTTPStatus || (HTTPStatus = {}));
export const HTTPStatusDescriptions = {
    [HTTPStatus.CONTINUE]: {
        reason: "Continue",
        description: "Request received, please continue",
    },
    [HTTPStatus.SWITCHING_PROTOCOLS]: {
        reason: "Switching Protocols",
        description: "Switching to new protocol; obey Upgrade header",
    },
    [HTTPStatus.PROCESSING]: { reason: "Processing" },
    [HTTPStatus.EARLY_HINTS]: { reason: "Early Hints" },
    [HTTPStatus.OK]: {
        reason: "OK",
        description: "Request fulfilled, document follows",
    },
    [HTTPStatus.CREATED]: {
        reason: "Created",
        description: "Document created, URL follows",
    },
    [HTTPStatus.ACCEPTED]: {
        reason: "Accepted",
        description: "Request accepted, processing continues off-line",
    },
    [HTTPStatus.NON_AUTHORITATIVE_INFORMATION]: {
        reason: "Non-Authoritative Information",
        description: "Request fulfilled from cache",
    },
    [HTTPStatus.NO_CONTENT]: {
        reason: "No Content",
        description: "Request fulfilled, nothing follows",
    },
    [HTTPStatus.RESET_CONTENT]: {
        reason: "Reset Content",
        description: "Clear input form for further input",
    },
    [HTTPStatus.PARTIAL_CONTENT]: {
        reason: "Partial Content",
        description: "Partial content follows",
    },
    [HTTPStatus.MULTI_STATUS]: { reason: "Multi-Status" },
    [HTTPStatus.ALREADY_REPORTED]: { reason: "Already Reported" },
    [HTTPStatus.IM_USED]: { reason: "IM Used" },
    [HTTPStatus.MULTIPLE_CHOICES]: {
        reason: "Multiple Choices",
        description: "Object has several resources -- see URI list",
    },
    [HTTPStatus.MOVED_PERMANENTLY]: {
        reason: "Moved Permanently",
        description: "Object moved permanently -- see URI list",
    },
    [HTTPStatus.FOUND]: {
        reason: "Found",
        description: "Object moved temporarily -- see URI list",
    },
    [HTTPStatus.SEE_OTHER]: {
        reason: "See Other",
        description: "Object moved -- see Method and URL list",
    },
    [HTTPStatus.NOT_MODIFIED]: {
        reason: "Not Modified",
        description: "Document has not changed since given time",
    },
    [HTTPStatus.USE_PROXY]: {
        reason: "Use Proxy",
        description: "You must use proxy specified in Location to access this resource",
    },
    [HTTPStatus.TEMPORARY_REDIRECT]: {
        reason: "Temporary Redirect",
        description: "Object moved temporarily -- see URI list",
    },
    [HTTPStatus.PERMANENT_REDIRECT]: {
        reason: "Permanent Redirect",
        description: "Object moved permanently -- see URI list",
    },
    [HTTPStatus.BAD_REQUEST]: {
        reason: "Bad Request",
        description: "Bad request syntax or unsupported method",
    },
    [HTTPStatus.UNAUTHORIZED]: {
        reason: "Unauthorized",
        description: "No permission -- see authorization schemes",
    },
    [HTTPStatus.PAYMENT_REQUIRED]: {
        reason: "Payment Required",
        description: "No payment -- see charging schemes",
    },
    [HTTPStatus.FORBIDDEN]: {
        reason: "Forbidden",
        description: "Request forbidden -- authorization will not help",
    },
    [HTTPStatus.NOT_FOUND]: {
        reason: "Not Found",
        description: "Nothing matches the given URI",
    },
    [HTTPStatus.METHOD_NOT_ALLOWED]: {
        reason: "Method Not Allowed",
        description: "Specified method is invalid for this resource",
    },
    [HTTPStatus.NOT_ACCEPTABLE]: {
        reason: "Not Acceptable",
        description: "URI not available in preferred format",
    },
    [HTTPStatus.PROXY_AUTHENTICATION_REQUIRED]: {
        reason: "Proxy Authentication Required",
        description: "You must authenticate with this proxy before proceeding",
    },
    [HTTPStatus.REQUEST_TIMEOUT]: {
        reason: "Request Timeout",
        description: "Request timed out; try again later",
    },
    [HTTPStatus.CONFLICT]: {
        reason: "Conflict",
        description: "Request conflict",
    },
    [HTTPStatus.GONE]: {
        reason: "Gone",
        description: "URI no longer exists and has been permanently removed",
    },
    [HTTPStatus.LENGTH_REQUIRED]: {
        reason: "Length Required",
        description: "Client must specify Content-Length",
    },
    [HTTPStatus.PRECONDITION_FAILED]: {
        reason: "Precondition Failed",
        description: "Precondition in headers is false",
    },
    [HTTPStatus.REQUEST_ENTITY_TOO_LARGE]: {
        reason: "Request Entity Too Large",
        description: "Entity is too large",
    },
    [HTTPStatus.REQUEST_URI_TOO_LONG]: {
        reason: "Request-URI Too Long",
        description: "URI is too long",
    },
    [HTTPStatus.UNSUPPORTED_MEDIA_TYPE]: {
        reason: "Unsupported Media Type",
        description: "Entity body in unsupported format",
    },
    [HTTPStatus.REQUESTED_RANGE_NOT_SATISFIABLE]: {
        reason: "Requested Range Not Satisfiable",
        description: "Cannot satisfy request range",
    },
    [HTTPStatus.EXPECTATION_FAILED]: {
        reason: "Expectation Failed",
        description: "Expect condition could not be satisfied",
    },
    [HTTPStatus.IM_A_TEAPOT]: {
        reason: "I'm a Teapot",
        description: "Server refuses to brew coffee because it is a teapot.",
    },
    [HTTPStatus.MISDIRECTED_REQUEST]: {
        reason: "Misdirected Request",
        description: "Server is not able to produce a response",
    },
    [HTTPStatus.UNPROCESSABLE_ENTITY]: { reason: "Unprocessable Entity" },
    [HTTPStatus.LOCKED]: { reason: "Locked" },
    [HTTPStatus.FAILED_DEPENDENCY]: { reason: "Failed Dependency" },
    [HTTPStatus.TOO_EARLY]: { reason: "Too Early" },
    [HTTPStatus.UPGRADE_REQUIRED]: { reason: "Upgrade Required" },
    [HTTPStatus.PRECONDITION_REQUIRED]: {
        reason: "Precondition Required",
        description: "The origin server requires the request to be conditional",
    },
    [HTTPStatus.TOO_MANY_REQUESTS]: {
        reason: "Too Many Requests",
        description: 'The user has sent too many requests in a given amount of time ("rate limiting")',
    },
    [HTTPStatus.REQUEST_HEADER_FIELDS_TOO_LARGE]: {
        reason: "Request Header Fields Too Large",
        description: "The server is unwilling to process the request because its header fields are too large",
    },
    [HTTPStatus.UNAVAILABLE_FOR_LEGAL_REASONS]: {
        reason: "Unavailable For Legal Reasons",
        description: "The server is denying access to the resource as a consequence of a legal demand",
    },
    [HTTPStatus.INTERNAL_SERVER_ERROR]: {
        reason: "Internal Server Error",
        description: "Server got itself in trouble",
    },
    [HTTPStatus.NOT_IMPLEMENTED]: {
        reason: "Not Implemented",
        description: "Server does not support this operation",
    },
    [HTTPStatus.BAD_GATEWAY]: {
        reason: "Bad Gateway",
        description: "Invalid responses from another server/proxy",
    },
    [HTTPStatus.SERVICE_UNAVAILABLE]: {
        reason: "Service Unavailable",
        description: "The server cannot process the request due to a high load",
    },
    [HTTPStatus.GATEWAY_TIMEOUT]: {
        reason: "Gateway Timeout",
        description: "The gateway server did not receive a timely response",
    },
    [HTTPStatus.HTTP_VERSION_NOT_SUPPORTED]: {
        reason: "HTTP Version Not Supported",
        description: "Cannot fulfill request",
    },
    [HTTPStatus.VARIANT_ALSO_NEGOTIATES]: { reason: "Variant Also Negotiates" },
    [HTTPStatus.INSUFFICIENT_STORAGE]: { reason: "Insufficient Storage" },
    [HTTPStatus.LOOP_DETECTED]: { reason: "Loop Detected" },
    [HTTPStatus.NOT_EXTENDED]: { reason: "Not Extended" },
    [HTTPStatus.NETWORK_AUTHENTICATION_REQUIRED]: {
        reason: "Network Authentication Required",
        description: "The client needs to authenticate to gain network access",
    },
};
