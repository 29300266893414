import { objectToCamel, objectToSnake } from "ts-case-convert";
import HttpAPI from "../http";
export default class HttpMissionControlAPI extends HttpAPI {
    async getInterviewAudio(iid, options) {
        try {
            const { url } = await this.request({
                method: "GET",
                url: `/audiostream/${iid}/mp3`,
                options,
            });
            return url;
        }
        catch (error) {
            if (error instanceof Response && error.status === 404) {
                return null;
            }
            throw error;
        }
    }
    async getQuestionAnswer(data, options) {
        return await this.request({
            method: "POST",
            url: "/models/question-answer/",
            data: objectToSnake(data),
            options,
        });
    }
    async getMeetingSummaryPrompts(options) {
        return await this.request({
            method: "GET",
            url: "/models/meeting-summary/prompts/",
            options,
        });
    }
    async getMeetingSummary(data, options) {
        return await this.request({
            method: "POST",
            url: "/models/meeting-summary/infer/",
            data: objectToSnake(data),
            options,
        });
    }
    async extractMeetingDetails(data, options) {
        return objectToCamel(await this.request({
            method: "POST",
            url: "/models/meeting-details/",
            data: objectToSnake(data),
            options,
        }));
    }
    async getFirebaseToken(options) {
        const { token, Error: error } = await this.request({
            method: "POST",
            url: "/api/firebase/gettoken",
            options,
        });
        if (!token) {
            throw new Error(error.message || "Failed to get Firebase token");
        }
        return token;
    }
    async startMeetingBot(data, options) {
        await this.request({
            method: "POST",
            url: "/api/recall/startbot",
            data: { botName: "AGi Talent Assistant", ...data },
            options,
        });
    }
    async stopMeetingBot(data, options) {
        await this.request({
            method: "POST",
            url: "/api/recall/stopbot",
            data,
            options,
        });
    }
    async setMeetingStatus(data, options) {
        const response = await this.request({
            method: "POST",
            url: "/api/meeting/setmeetingstatus",
            data,
            options,
        });
        if (response.Error) {
            throw new Error(response.Error);
        }
    }
    async updateConnectedDetails(data, options) {
        const response = await this.request({
            method: "POST",
            url: "/api/meeting/putg2details",
            data,
            options,
        });
        if (response.Error) {
            throw new Error(response.Error);
        }
    }
    async checkMeetingTranscript(iid, options) {
        await this.request({
            method: "POST",
            url: "/api/meeting/checkmeetingtranscript",
            data: { iId: iid },
            options,
        });
    }
    async postMeetingCleanup({ iId, ...data }, options) {
        await this.request({
            method: "POST",
            url: `/interview/postmeetingcleanup/${iId}`,
            data,
            options,
        });
    }
}
