import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, mergeClasses, slot, } from "@fluentui/react-components";
function useGrid(props, ref) {
    return {
        components: { root: "div" },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useGridStyles(state) {
    state.root.className = mergeClasses("ms-Grid", state.root.className);
}
function renderGrid(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Grid = forwardRef((props, ref) => {
    const state = useGrid(props, ref);
    useGridStyles(state);
    return renderGrid(state);
});
Grid.displayName = "Grid";
export default Grid;
