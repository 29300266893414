import type { Recruiter } from "@aglocal/web/schema/Interview";

import { useAuth } from "@/auth";
import getUserId from "@/helpers/getUserId";

export default function useIsCurrentUser(recruiter: Recruiter) {
  const auth = useAuth();

  if (recruiter.aADID == null || recruiter.aADID === "") {
    return false;
  }

  return getUserId(auth) === recruiter.aADID;
}
