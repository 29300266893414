import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { makeStyles } from "@fluentui/react-components";
import { AbortError } from "@aglocal/web/helpers/abort";
import { HTTPStatusDescriptions } from "@aglocal/web/helpers/http";
import asError from "@aglocal/web/helpers/asError";
const useStyles = makeStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
});
function ErrorDetails({ error }) {
    if (error instanceof AbortError) {
        return (_jsxs(_Fragment, { children: [_jsx("p", { children: error.reason }), error.message && _jsx("pre", { children: error.message })] }));
    }
    if (error instanceof Response) {
        const { description } = HTTPStatusDescriptions[error.status] ?? {};
        return (_jsxs(_Fragment, { children: [_jsx("p", { children: error.statusText }), description && _jsx("pre", { children: description })] }));
    }
    return null;
}
export default function RouterErrorBoundary() {
    const styles = useStyles();
    const error = useRouteError();
    useEffect(() => {
        if (error == null) {
            return;
        }
        reportError(asError(error));
    }, [error]);
    return (_jsxs("div", { className: styles.container, children: [_jsx("h1", { children: "Oops!" }), _jsx("p", { children: "Sorry, an unexpected error has occurred." }), _jsx(ErrorDetails, { error: error })] }));
}
