import React, { useMemo } from "react";

import { useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";

import useReportError from "@aglocal/web/hooks/useReportError";

import { MSAL_SCOPES } from "@/auth";

import LoadingPage from "@/pages/Loading";
import ErrorPage from "@/pages/Error";

import MsalAuthManager from "./MsalAuthManager";
import { MslaAuthState } from "./context";
import AuthServices from "./AuthServices";

export interface MsalAuthenticatedProps {
  roles?: string[];
  children: React.ReactNode;
}

export default function MsalAuthenticated({
  roles,
  children,
}: MsalAuthenticatedProps) {
  const { instance, accounts, inProgress } = useMsal();
  const { error } = useMsalAuthentication(InteractionType.Redirect, {
    scopes: MSAL_SCOPES,
  });

  const auth = useMemo(
    () => new MsalAuthManager(instance, { scopes: MSAL_SCOPES }),
    [instance],
  );

  const account = accounts.length === 0 ? null : accounts[0];

  const state = useMemo<MslaAuthState | null>(
    () =>
      account && {
        provider: "msal",
        auth,
        instance,
        account,
      },
    [account, auth, instance],
  );

  useReportError(error);

  if (error && inProgress === InteractionStatus.None) {
    return <ErrorPage title="Login Failed">{error.message}</ErrorPage>;
  }

  if (state == null || inProgress === InteractionStatus.Startup) {
    return <LoadingPage />;
  }

  const authorizedRoles: Record<string, boolean> = {};
  state.account.idTokenClaims?.roles?.forEach((role) => {
    authorizedRoles[role] = true;
  });

  if (roles?.some((role) => !authorizedRoles[role])) {
    return (
      <ErrorPage title="Unauthorized">
        You are not authorized to view this page.
      </ErrorPage>
    );
  }

  return <AuthServices state={state}>{children}</AuthServices>;
}
