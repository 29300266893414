export const skillsBlacklist = [
    "Ability To Meet Deadlines",
    "Accountability",
    "Adaptability",
    "Advising",
    "Advocacy",
    "Creativity",
    "Critical Thinking",
    "Curiosity",
    "Debating",
    "Detail Oriented",
    "Diplomacy",
    "Empathy",
    "Enthusiasm",
    "Honesty",
    "Needs Assessment",
    "Negative Feedback",
    "Open Mindset",
    "OptimismPatience",
    "Proactivity",
    "Professionalism",
    "Resilience",
    "Roasting",
    "Self-Directed Learning",
    "Self-MotivationSortingSparse",
    "Sexual Harassment Awareness",
    "Strong Work Ethic",
    "Syntax",
    "Team BuildingTeamwork",
    "Tenacity",
    "Thought Leadership",
    "Triage",
    "Trustworthiness",
    "Turnaround Time",
    "Unsupervised Learning",
    "Usability",
    "Vulnerability",
    "Willingness To Learn",
    "Working With Children"
];
