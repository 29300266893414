import { useLayoutEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Textarea,
  makeStyles,
} from "@fluentui/react-components";

import type Interview from "@aglocal/web/schema/Interview";
import useUpdateInterview from "@aglocal/web/firebase/queries/useUpdateInterview";
import useIsCurrentUser from "@/hooks/useIsCurrentUser";

const useStyles = makeStyles({
  input: {
    height: "25rem",
    width: "100%",
  },
});

export interface NotesAccordionItemProps {
  name: unknown;
  interview: Interview;
}

export default function NotesAccordionItem({
  name,
  interview,
}: NotesAccordionItemProps) {
  const styles = useStyles();
  const isCurrentUser = useIsCurrentUser(interview.interviewer);
  const updateInterview = useUpdateInterview();

  const [notes, setNotes] = useState(interview.inMeetingNotes ?? "");

  const updateNotes = useDebouncedCallback(
    (inMeetingNotes: string) => {
      void updateInterview(interview.id, { inMeetingNotes });
    },
    1000,
    {
      maxWait: 5000,
    },
  );

  // Use layout effect to flush the debounced callback before it is unmounted
  useLayoutEffect(
    () => () => {
      updateNotes.flush();
    },
    [updateNotes],
  );

  const handleChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(value);
    updateNotes(value);
  };

  return (
    <AccordionItem value={name}>
      <AccordionHeader>Notes</AccordionHeader>
      <AccordionPanel>
        <Textarea
          placeholder={
            isCurrentUser
              ? "Add meeting notes here..."
              : "Meeting Notes will be shown here."
          }
          className={styles.input}
          readOnly={!isCurrentUser}
          value={notes}
          onChange={handleChange}
        />
      </AccordionPanel>
    </AccordionItem>
  );
}
