import { formatLocalISO } from "@aglocal/web/helpers/time";
export function formatTranscript(transcript) {
    if (transcript == null) {
        return null;
    }
    const combined = [];
    for (const { speaker, words } of transcript) {
        const previousSpeaker = combined.length
            ? combined[combined.length - 1].speaker
            : null;
        if (previousSpeaker == null || previousSpeaker !== speaker) {
            combined.push({ speaker, words: [] });
        }
        combined[combined.length - 1].words.push(...words);
    }
    return combined.map(({ speaker, words }) => ({
        speaker: speaker ?? "Unknown",
        text: words.map(({ text }) => text).join(" "),
    }));
}
export function getContext(interview) {
    const start = new Date(interview.startTimeDate);
    const now = new Date();
    return {
        interviewer: interview.interviewer.displayName ?? "Unknown",
        interviewee: interview.interviewee.displayName,
        startTime: formatLocalISO(start),
        now: formatLocalISO(now),
    };
}
