import {
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
} from "@fluentui/react-components";

import Interview from "@aglocal/web/schema/Interview";
import Callout from "@aglocal/web/components/Callout";

import ConnectedDetails from "@/components/ConnectedDetails";

export interface ConnectedDetailsAccordionItemProps {
  name: unknown;
  interview: Interview;
}

export default function ConnectedDetailsAccordionItem({
  name,
  interview: { interviewee: { connectedDetails } },
}: ConnectedDetailsAccordionItemProps) {
  return (
    <AccordionItem value={name}>
      <AccordionHeader>Connected Details</AccordionHeader>
      <AccordionPanel>
        {connectedDetails == null ? (
          <Callout intent="warning" title="Connected Details not found" />
        ) : (
          <ConnectedDetails details={connectedDetails} />
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}
