import { ReactNode } from "react";

import { signInWithCustomToken } from "firebase/auth";

import useAsync from "@aglocal/web/hooks/useAsync";
import useReportError from "@aglocal/web/hooks/useReportError";
import { useFirebaseAuth } from "@aglocal/web/firebase";
import { useMissionControl } from "@aglocal/web/mission-control";

import LoadingPage from "@/pages/Loading";
import ErrorPage from "@/pages/Error";

export interface FirebaseAuthProviderProps {
  children: ReactNode;
}

export default function FirebaseAuthProviderProps({
  children,
}: FirebaseAuthProviderProps) {
  const auth = useFirebaseAuth();
  const api = useMissionControl();

  const { loading, error } = useAsync(
    async (signal) => {
      const token = await api.getFirebaseToken({ signal });
      await signInWithCustomToken(auth, token);
    },
    [auth, api],
  );

  useReportError(error);

  if (error) {
    return (
      <ErrorPage title="Firebase Authentication Failed">
        {error.message}
      </ErrorPage>
    );
  }

  if (loading) {
    return <LoadingPage />;
  }

  return children;
}
