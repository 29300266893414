import { useMemo } from "react";
import { collection } from "firebase/firestore";
import { asType, useDocs, useFirestore } from "@aglocal/web/firebase";
import UniqueList from "@aglocal/web/helpers/UniqueList";
import { isExcludedSkill } from "@aglocal/web/helpers/filterSkills";
import useModelOutput from "./useModelOutput";
import normalizeSkill from "@aglocal/web/helpers/normalizeSkill";
const InferredSkillConverter = asType();
export default function useDarkquell(id) {
    const db = useFirestore();
    const tempOutput = useDocs(collection(db, "darkquell_temp", id, "skills").withConverter(InferredSkillConverter));
    const modelOutput = useModelOutput(id);
    const result = useMemo(() => {
        const skills = [];
        const index = new Map();
        const addSkill = ({ skill, sources, max_confidence, }) => {
            if (isExcludedSkill(skill.name)) {
                return;
            }
            const accumulator = index.get(skill.id);
            if (accumulator == null) {
                skills.push(skill);
                index.set(skill.id, {
                    sources: new UniqueList(sources, ({ text }) => normalizeSkill(text)),
                    max_confidence: max_confidence,
                });
            }
            else {
                accumulator.max_confidence = Math.max(accumulator.max_confidence, max_confidence);
                sources.forEach((source) => {
                    accumulator.sources.add(source);
                });
            }
        };
        modelOutput.snapshot?.data()?.darkquell?.skills.forEach(addSkill);
        tempOutput.snapshot?.forEach((doc) => {
            addSkill(doc.data());
        });
        return skills.map((skill) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const { sources, max_confidence } = index.get(skill.id);
            return { skill, max_confidence, sources: Array.from(sources) };
        });
    }, [modelOutput.snapshot, tempOutput.snapshot]);
    return [
        result,
        modelOutput.loading || tempOutput.loading,
        modelOutput.error ?? tempOutput.error,
    ];
}
