import { useCallback, useEffect, useRef, useState } from "react";
function useSafeState(initialState) {
    const [state, setState] = useState(initialState);
    const componentMountedRef = useRef(false);
    useEffect(() => {
        // Set the flag on mount
        componentMountedRef.current = true;
        // Reset the flag on unmount (cleanup)
        return () => {
            componentMountedRef.current = false;
        };
    }, [componentMountedRef]);
    const safeSetState = useCallback((value) => {
        // Only set the state when the component is mounted
        if (componentMountedRef.current) {
            setState(value);
        }
    }, [setState, componentMountedRef]);
    return [state, safeSetState];
}
export default useSafeState;
