import { FormattedMessage } from "react-intl";
import {
  makeStyles,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  tokens,
} from "@fluentui/react-components";

import {
  DarkquellInferredSkill,
  DarkquellSkill,
  DarkquellSkillSource,
  LLMNEREntity,
} from "@aglocal/web/schema/ModelOutput";

import titleCase from "@aglocal/web/helpers/titleCase";

import Tag, { TagGroup } from "@aglocal/web/components/Tag";
import { Text, Heading } from "@aglocal/web/components/Typography";

const LABELS: Record<string, string> = {
  SKILL: "Skill",
  NEG: "Negative Skill",
  LOC: "Location",
  ORG: "Organization",
  PER: "Person",
  TITLE: "Title",
};

const useStyles = makeStyles({
  popover: {
    maxWidth: "36rem",
  },
  popoverHeader: {
    marginTop: 0,
    marginBottom: tokens.spacingVerticalS,
  },
  entitySKILL: {
    backgroundColor: tokens.colorPaletteGreenBackground2,
  },
  entityLOC: {
    backgroundColor: tokens.colorPaletteBrownBackground2,
  },
  entityNEG: {
    backgroundColor: tokens.colorPaletteRedBackground2,
  },
  entityORG: {
    backgroundColor: tokens.colorPaletteSteelBackground2,
  },
  entityPER: {
    backgroundColor: tokens.colorPaletteMarigoldBackground2,
  },
  entityTITLE: {
    backgroundColor: tokens.colorPaletteCornflowerBackground2,
  },
});

interface DarkquellSkillPillProps {
  skill: DarkquellSkill;
  sources: DarkquellSkillSource[];
}

function DarkquellSkillPill({ skill, sources }: DarkquellSkillPillProps) {
  const styles = useStyles();
  return (
    <Popover openOnHover withArrow mouseLeaveDelay={0}>
      <PopoverTrigger>
        <Tag className={styles.entitySKILL}>{skill.name}</Tag>
      </PopoverTrigger>
      <PopoverSurface tabIndex={-1} className={styles.popover}>
        {skill.description && (
          <>
            <Heading
              as="h3"
              variant="subtitle2"
              className={styles.popoverHeader}
            >
              Skill Description
            </Heading>
            <Text>{skill.description}</Text>
          </>
        )}
        {sources.length !== 0 && (
          <>
            <Heading
              as="h3"
              variant="subtitle2"
              className={styles.popoverHeader}
            >
              <FormattedMessage
                values={{ sourceCount: sources.length }}
                defaultMessage={`
                  Found in {sourceCount, plural,
                    one {# sentence}
                    other {# sentences}
                  }
                `}
              />
            </Heading>
            {sources.map((source, index) => (
              <Text key={index}>{source.text}</Text>
            ))}
          </>
        )}
      </PopoverSurface>
    </Popover>
  );
}

function groupEntitiesByLabel(
  entities: readonly LLMNEREntity[],
): LLMNEREntity[] {
  const groups = new Map<string, LLMNEREntity[]>();
  for (const entity of entities) {
    const label = entity.label;
    const group = groups.get(label) ?? [];
    group.push(entity);
    groups.set(label, group);
  }
  return Array.from(groups.values()).flat();
}

interface LLMNERSkillPillProps {
  entity: LLMNEREntity;
}

function LLMNERSkillPill({ entity }: LLMNERSkillPillProps) {
  const styles = useStyles();
  const variant = `entity${entity.label}` as keyof typeof styles;

  return (
    <Popover openOnHover withArrow mouseLeaveDelay={0}>
      <PopoverTrigger>
      <Tag className={styles[variant]}>{titleCase(entity.text)}</Tag>
      </PopoverTrigger>
      <PopoverSurface tabIndex={-1} className={styles.popover}>
        {LABELS[entity.label] || titleCase(entity.label)}
      </PopoverSurface>
    </Popover>
  );
}

export interface SkillPillsProps {
  skills?: readonly DarkquellInferredSkill[];
  entities?: readonly LLMNEREntity[];
}

export default function SkillPills({
  skills = [],
  entities = [],
}: SkillPillsProps) {
  return (
    <TagGroup>
      {[...skills].reverse().map(({ skill, sources }) => (
        <DarkquellSkillPill key={skill.name} skill={skill} sources={sources} />
      ))}
      {groupEntitiesByLabel(entities)
        .reverse()
        .map((entity) => (
          <LLMNERSkillPill key={entity.text} entity={entity} />
        ))}
    </TagGroup>
  );
}
