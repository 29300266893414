import { HTTPStatusDescriptions } from "./http";
export { HTTPStatus } from "./http";
export class AbortError extends Error {
    constructor(status, reason, message) {
        super(message);
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "reason", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.status = status;
        this.reason = reason;
    }
}
export default function abort(status, description) {
    const message = HTTPStatusDescriptions[status];
    throw new AbortError(status, message.reason, description ?? message.description ?? "");
}
