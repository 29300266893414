import { jsx as _jsx } from "@fluentui/react-jsx-runtime/jsx-runtime";
/** @jsxRuntime automatic */
/** @jsxImportSource @fluentui/react-jsx-runtime */
import { forwardRef } from "react";
import { assertSlots, getIntrinsicElementProps, mergeClasses, slot, } from "@fluentui/react-components";
function useRow(props, ref) {
    return {
        components: { root: "div" },
        root: slot.always(getIntrinsicElementProps("div", { ref, ...props }), {
            elementType: "div",
        }),
    };
}
function useRowStyles(state) {
    state.root.className = mergeClasses("ms-Grid-row", state.root.className);
}
function renderRow(state) {
    assertSlots(state);
    return _jsx(state.root, {});
}
export const Row = forwardRef((props, ref) => {
    const state = useRow(props, ref);
    useRowStyles(state);
    return renderRow(state);
});
Row.displayName = "Row";
export default Row;
